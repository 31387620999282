import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import AchForm from 'components/expense/ExpenseProfilePage/ExpensePaymentOptionsSection/AchForm';
import AddressForm from 'components/expense/ExpenseProfilePage/ExpensePaymentOptionsSection/AddressForm';
import { ParametersContext } from 'components/ParametersGuard';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import ExpenseService from 'services/api/ExpenseService';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import { ExpenseProfile, PaymentMethodTypeUpdateRequest } from 'store/types/Expense';
import { PaymentFormValues } from 'store/types/FormValues';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { defaultPaymentFormValues } from 'util/Payment';

import styles from 'components/expense/Expense.module.scss';

interface ExpensePaymentOptionsSectionProps {
  data: ExpenseProfile;
}

const ExpensePaymentOptionsSection: React.FunctionComponent<ExpensePaymentOptionsSectionProps> = ({ data }) => {
  const {
    modulesSettings: {
      expenseProfile: { profilePaymentOptionsTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseProfile: { reimbursementOptionsTabHeader, profilePaymentSelectionHeader },
  } = useContext(ParametersContext);

  const { control, reset } = useForm({ ...defaultFormProps });
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentForm, setPaymentForm] = useState<PaymentFormValues>({
    ...defaultPaymentFormValues,
    useHomeAddress: true,
  });

  useEffect(() => {
    if (data) {
      reset(data);
      setPaymentForm((prevState) => ({
        ...prevState,
        paymentMethodType: PaymentMethodType[data.paymentMethodType as keyof typeof PaymentMethodType],
      }));
    }
  }, [data, reset]);

  const handlePaymentMethodChange = useCallback(
    (onChange) => (e: ChangeEvent<HTMLInputElement>) => {
      const paymentMethodType = e.target.value;
      onChange(paymentMethodType);
      setSubmitLoading(true);

      ExpenseService.updatePaymentMethodType({ paymentMethodType } as PaymentMethodTypeUpdateRequest)
        .then(() => {
          enqueueSnackbar('Payment option successfully updated', { variant: 'success' });
        })
        .catch((error: string) => {
          enqueueSnackbar(error, defaultSnackbarErrorProps);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return (
    <div className={styles.contentBorder}>
      {reimbursementOptionsTabHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reimbursementOptionsTabHeader }} />
      )}

      {profilePaymentOptionsTitle && <h2>{profilePaymentOptionsTitle}</h2>}

      {profilePaymentSelectionHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: profilePaymentSelectionHeader }} />
      )}

      <Spinner loading={submitLoading} transparent={true}>
        <>
          <FormControl component={'fieldset'} fullWidth={true} required={true}>
            <Controller
              name={'paymentMethodType'}
              control={control}
              defaultValue={paymentForm.paymentMethodType}
              render={({ field: { onChange, value } }) => (
                <RadioGroup value={value} onChange={handlePaymentMethodChange(onChange)} className={styles.radioGroup}>
                  <FormControlLabel
                    key={PaymentMethodType.Check}
                    label={PaymentMethodType.Check}
                    value={PaymentMethodType.Check}
                    control={<Radio color={'primary'} />}
                  />
                  <FormControlLabel
                    key={PaymentMethodType.Ach}
                    label={PaymentMethodType.Ach.toUpperCase()}
                    value={PaymentMethodType.Ach}
                    control={<Radio color={'primary'} />}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>

          {paymentForm.paymentMethodType === PaymentMethodType.Check && <AddressForm />}

          {paymentForm.paymentMethodType === PaymentMethodType.Ach && data && (
            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps}>
                <AchForm data={data?.achPayment} />
              </Grid>
            </Grid>
          )}
        </>
      </Spinner>
    </div>
  );
};

export default ExpensePaymentOptionsSection;
