import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Add, ArrowBack } from '@mui/icons-material';
import { Alert, Button, Grid, Link, TextField } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import ExpenseItemTable from 'components/expense/ExpenseReportCollectionPage/ExpenseItemTable';
import { getStatusLabel } from 'components/expense/ExpenseReportCollectionPage/ExpenseReportTable';
import ConfirmSubmitReportModal from 'components/expense/ExpenseReportPage/ConfirmSubmitReportModal';
import DeleteExpenseModal from 'components/expense/ExpenseReportPage/DeleteExpenseModal';
import ExpenseFormModal from 'components/expense/ExpenseReportPage/ExpenseFormModal';
import reducer, {
  ExpenseReportAction,
  ExpenseReportActionType,
  ExpenseReportState,
  initialState,
} from 'components/expense/ExpenseReportPage/ExpenseReportReducer';
import { ParametersContext } from 'components/ParametersGuard';
import FormFooter from 'components/shared/FormFooter';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import ExpenseService from 'services/api/ExpenseService';
import routes from 'store/configs/Routes';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';
import {
  ExpenseCategory,
  ExpenseForm,
  ExpenseItem,
  ExpenseReport,
  ExpenseReportUpdateRequest,
} from 'store/types/Expense';
import {
  defaultFormProps,
  FREEFORM_TEXT_MAX_LENGTH,
  getMaxLengthValidationRule,
  getRequiredValidationRule,
  getValidationProps,
} from 'util/Form';
import { getFullDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { EXPENSE_DELEGATOR_ID, getLocalStorageValue } from 'util/LocalStorage';
import { getPrice } from 'util/Payment';
import { getHashRouteUrl } from 'util/Route';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

const defaultValues: ExpenseForm = {
  id: '',
  delegatorProfileId: '',
  memo: '',
  isDraft: true,
  status: '',
  expense: {
    id: '',
    date: null,
    category: { id: '', name: '', isQtyRateCategory: false } as ExpenseCategory,
    categoryName: '',
    accountId: '',
    accountName: '',
    quantity: '',
    rate: '',
    amount: '',
    memo: '',
    receiptFile: null,
    receiptFileUploads: [],
  } as ExpenseItem,
};

const ExpenseReportPage: React.FunctionComponent = () => {
  const form = useForm<ExpenseForm>({ ...defaultFormProps, defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = form;

  const {
    modulesSettings: {
      expenseReport: { reportExpensesTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseReport: {
      expenseReportExpensesHeader,
      expenseReportPageHeader,
      expenseReportNewExpenseHeader,
      expenseReportPurposeHeader,
    },
  } = useContext(ParametersContext);

  const [
    {
      deleteExpenseConfirmationOpen,
      expenseModalOpen,
      loading: submitLoading,
      readOnly,
      selectedExpenseItem,
      submitReportConfirmationOpen,
    },
    dispatch,
  ] = useReducer<Reducer<ExpenseReportState, ExpenseReportAction>>(reducer, initialState);

  const { expenseReportId = '' } = useParams<{ expenseReportId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [delegatorProfileId, setDelegatorProfileId] = useState('');

  const expenseReportRequest = useMemo(
    () => (expenseReportId === 'new' ? undefined : () => ExpenseService.getSingleExpenseReport(expenseReportId)),
    [expenseReportId]
  );

  const { data, error, loading, refetch } = useRequest<ExpenseReport>(expenseReportRequest);

  const submitButtonDisabled: boolean = useMemo(
    () => !isValid || loading || submitLoading || !data?.expenses,
    [isValid, loading, submitLoading, data?.expenses]
  );

  useEffect(() => {
    if (data) {
      reset(data);

      dispatch({
        type: ExpenseReportActionType.Initialize,
        payload: { expenseReport: data },
      });
    }
  }, [data, reset]);

  useEffect(() => {
    setDelegatorProfileId(getLocalStorageValue(EXPENSE_DELEGATOR_ID) as string);
  }, []);

  const getReturnRouteUrl = useMemo(() => {
    return delegatorProfileId ? routes.expenseReportOtherPerson : routes.expenseReport;
  }, [delegatorProfileId]);

  const getReturnHashRouteUrl = useMemo(() => {
    return delegatorProfileId
      ? getHashRouteUrl(routes.expenseReportOtherPerson)
      : getHashRouteUrl(routes.expenseReport);
  }, [delegatorProfileId]);

  const handleEditExpenseModalOpen = useCallback(
    (expenseId?: string) => {
      const expense = expenseId ? data?.expenses.find((item) => item.id === expenseId) ?? null : null;

      const expenseItem = {
        id: data?.id,
        memo: data?.memo,
        expense: expense,
        status: data?.status ?? '',
      } as ExpenseForm;

      dispatch({
        type: ExpenseReportActionType.ToggleExpenseModal,
        payload: { selectedExpenseItem: expenseItem },
      });
    },
    [data]
  );

  const handleCreateExpenseModalOpen = useCallback((data: ExpenseForm) => {
    const delegatorIdLocalStorage = getLocalStorageValue(EXPENSE_DELEGATOR_ID) as string;

    const expenseItem = {
      delegatorProfileId: delegatorIdLocalStorage,
      memo: data?.memo,
    } as ExpenseForm;

    dispatch({
      type: ExpenseReportActionType.ToggleExpenseModal,
      payload: { selectedExpenseItem: expenseItem },
    });
  }, []);

  const handleConfirmDeleteExpense = useCallback(
    (expenseId: string) => {
      if (data) {
        const expense = expenseId ? data?.expenses.find((item) => item.id === expenseId) ?? null : null;

        const expenseItem = {
          id: data.id,
          expense: expense,
        } as ExpenseForm;

        dispatch({
          type: ExpenseReportActionType.ToggleDeleteExpenseConfirmation,
          payload: { selectedExpenseItem: expenseItem },
        });
      }
    },
    [data]
  );

  const handleConfirmDeleteExpenseModalClose = useCallback(() => {
    dispatch({
      type: ExpenseReportActionType.ToggleDeleteExpenseConfirmation,
      payload: {},
    });
  }, []);

  const handleConfirmSubmitModalClose = useCallback(() => {
    dispatch({
      type: ExpenseReportActionType.ToggleSubmitReportConfirmationModal,
      payload: {},
    });
  }, []);

  const handleDeleteExpenseModalSubmit = useCallback(() => {
    handleConfirmDeleteExpenseModalClose();

    refetch();
  }, [handleConfirmDeleteExpenseModalClose, refetch]);

  const handleExpenseModalClose = useCallback(() => {
    dispatch({
      type: ExpenseReportActionType.ToggleExpenseModal,
      payload: {},
    });
  }, []);

  const handleReportSubmit = useCallback(
    (data: ExpenseForm, isDraft: boolean) => {
      const expenseReportUpdateRequest = {
        delegatorProfileId: delegatorProfileId,
        memo: data?.memo,
        isDraft: isDraft,
      } as ExpenseReportUpdateRequest;

      dispatch({
        type: ExpenseReportActionType.SetLoading,
        payload: { loading: true },
      });

      ExpenseService.updateExpenseReport(expenseReportId, expenseReportUpdateRequest)
        .then(() => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar('Expense report successfully saved', { variant: 'success' });
          history.push(getReturnRouteUrl);
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [delegatorProfileId, enqueueSnackbar, expenseReportId, getReturnRouteUrl, history]
  );

  const handleConfirmSubmit = useCallback(() => {
    dispatch({
      type: ExpenseReportActionType.ToggleSubmitReportConfirmationModal,
      payload: {},
    });
  }, []);

  const handleReportSubmitApproval = useCallback(
    (data: ExpenseForm) => {
      dispatch({
        type: ExpenseReportActionType.ToggleSubmitReportConfirmationModal,
        payload: {},
      });

      handleReportSubmit(data, false);
    },
    [handleReportSubmit]
  );

  const handleReportSubmitDraft = useCallback(
    (data: ExpenseForm) => {
      handleReportSubmit(data, true);
    },
    [handleReportSubmit]
  );

  const handleExpenseSubmit = useCallback(() => {
    refetch();
    dispatch({
      type: ExpenseReportActionType.SetLoading,
      payload: { loading: false },
    });
  }, [refetch]);

  const handleDownloadReceipt = useCallback(
    (expenseReportId, expenseId, filename) => {
      dispatch({
        type: ExpenseReportActionType.SetLoading,
        payload: { loading: true },
      });
      ExpenseService.getReceiptFile(expenseReportId, expenseId, filename)
        .then(() => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportActionType.SetLoading,
            payload: { loading: false },
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <>
      <Grid {...defaultGridContainerProps} spacing={0} className={styles.pageTitleSection}>
        <Grid {...defaultGridItemProps} sm={12} marginBottom={2}>
          <Link href={getReturnHashRouteUrl} className={styles.backLink} underline={'always'} color={'inherit'}>
            <ArrowBack className={styles.backIcon} />
            {'Back to Expense Report Portal'}
          </Link>
        </Grid>
        <Grid {...defaultGridItemProps} xs={8}>
          <h1 className={commonStyles.pageTitle}>
            {`${expenseReportId === 'new' ? 'New ' : ''}${reportExpensesTitle}`}
          </h1>
        </Grid>
        <Grid {...defaultGridItemProps} xs={4} className={styles.pullRight}>
          {data && <div className={styles.reportPageStatus}>{getStatusLabel(data.status as ExpenseReportStatus)}</div>}
        </Grid>
      </Grid>

      {expenseReportPageHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: expenseReportPageHeader }} />
      )}

      {data?.reviews && data.reviews.length > 0 && (
        <div className={styles.contentBorder}>
          <div className={styles.reviewHeading}>Reviewer's Comments</div>
          {data?.reviews?.map((review) => (
            <div key={`expense-review-${review.id}`} className={styles.contentBlock}>
              <div className={styles.reviewLabel}>
                <span>
                  {getFullDate(review.date.toString())} {review.firstName} {review.lastName}
                </span>
                {review?.title && <span> - {review.title}</span>}
                {review?.company && <span> - {review.company}</span>}
              </div>
              <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: review.comments }} />
            </div>
          ))}
        </div>
      )}

      <Spinner loading={loading || submitLoading} transparent={true}>
        <FormProvider {...form}>
          <div className={styles.contentBorder}>
            <h2 className={styles.contentBlock}>{'Purpose'}</h2>

            {expenseReportPurposeHeader && (
              <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: expenseReportPurposeHeader }} />
            )}

            <Grid {...defaultGridContainerProps}>
              <Grid {...defaultGridItemProps}>
                <Controller
                  name={'memo'}
                  control={control}
                  rules={{
                    required: getRequiredValidationRule('Purpose'),
                    maxLength: getMaxLengthValidationRule(FREEFORM_TEXT_MAX_LENGTH),
                  }}
                  render={({ field }) => (
                    <TextField
                      label={'Purpose'}
                      {...field}
                      {...getValidationProps('memo', errors)}
                      required={true}
                      disabled={readOnly}
                      multiline={true}
                      minRows={3}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>

          <div className={styles.contentBorder}>
            <h2>{'Expenses'}</h2>

            {expenseReportExpensesHeader && (
              <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: expenseReportExpensesHeader }} />
            )}

            {!readOnly &&
              (data ? (
                <Button
                  color={'primary'}
                  variant={'contained'}
                  startIcon={<Add />}
                  className={styles.contentBlock}
                  onClick={() => handleEditExpenseModalOpen()}
                >
                  {'Add an Expense'}
                </Button>
              ) : (
                <Button
                  color={'primary'}
                  variant={'contained'}
                  startIcon={<Add />}
                  className={styles.contentBlock}
                  onClick={handleSubmit(handleCreateExpenseModalOpen)}
                >
                  {'Add an Expense'}
                </Button>
              ))}

            {!loading && data && (
              <ExpenseItemTable
                data={data}
                onModalOpen={handleEditExpenseModalOpen}
                onDeleteExpense={handleConfirmDeleteExpense}
                onDownloadReceipt={handleDownloadReceipt}
              />
            )}
          </div>

          {data?.amount && <div className={styles.total}>Total: {getPrice(Number(data?.amount))}</div>}

          {!readOnly && (
            <FormFooter
              onSubmit={handleConfirmSubmit}
              submitButtonName={'Submit for Approval'}
              submitButtonDisabled={submitButtonDisabled}
              className={styles.buttonPadding}
            >
              <Button
                color={'primary'}
                variant={'outlined'}
                disabled={submitButtonDisabled}
                onClick={handleSubmit(handleReportSubmitDraft)}
              >
                Save Draft
              </Button>
            </FormFooter>
          )}
        </FormProvider>
      </Spinner>

      <ExpenseFormModal
        expenseForm={selectedExpenseItem}
        isReadOnly={readOnly}
        defaultValues={defaultValues}
        headerBlock={expenseReportNewExpenseHeader}
        open={expenseModalOpen}
        onSubmit={handleExpenseSubmit}
        onClose={handleExpenseModalClose}
      />

      <DeleteExpenseModal
        expenseReportId={selectedExpenseItem?.id ?? ''}
        expenseItemId={selectedExpenseItem?.expense?.id ?? ''}
        delegatorProfileId={delegatorProfileId}
        open={deleteExpenseConfirmationOpen}
        onSubmit={handleDeleteExpenseModalSubmit}
        onClose={handleConfirmDeleteExpenseModalClose}
      />

      <ConfirmSubmitReportModal
        open={submitReportConfirmationOpen}
        onSubmit={handleSubmit(handleReportSubmitApproval)}
        onClose={handleConfirmSubmitModalClose}
      />
    </>
  );
};

export default ExpenseReportPage;
