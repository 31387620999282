import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, MenuItem, TextField } from '@mui/material';
import useRequest from 'hooks/useRequest';
import ExpenseService from 'services/api/ExpenseService';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { UserEmployerAccessRequest, UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';
import {
  EXPENSE_DELEGATOR_ID,
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
} from 'util/LocalStorage';

import commonStyles from 'styles/common.module.scss';

interface DelegationSelectListProps {
  onChange: (delegatorId: string) => void;
  onLoad: (delegatorId: string) => void;
}

const clearLocalStorage = () => {
  removeLocalStorageValue(EXPENSE_DELEGATOR_ID);
};

const getSelectedDelegator = (requests: UserEmployerAccessRequest[], delegatorId: string) => {
  return requests.find((item) => item.approverId === delegatorId);
};

const filterApprovedRequests = (requests: UserEmployerAccessRequestsData) => {
  if (requests?.outgoing?.length === 0) {
    return [];
  }
  const approvedDelegationRequests = requests.outgoing.filter(
    (item) => item.status === CompanyAccessRequestStatus.Approved
  );

  if (approvedDelegationRequests.length === 0) {
    return [];
  }

  const uniqueRequests = approvedDelegationRequests.reduce(
    (acc: UserEmployerAccessRequest[], currentValue: UserEmployerAccessRequest) => {
      if (!acc.find((item) => item.approverId === currentValue.approverId)) {
        acc.push(currentValue);
      }
      return acc;
    },
    []
  );

  return uniqueRequests.sort((a: UserEmployerAccessRequest, b: UserEmployerAccessRequest) =>
    a.name.localeCompare(b.name, undefined, { ignorePunctuation: true })
  );
};

const DelegationSelectList: React.FunctionComponent<DelegationSelectListProps> = ({ onChange, onLoad }) => {
  const [selectedDelegatorId, setSelectedDelegatorId] = useState<string>('');
  const [approvedDelegationRequests, setApprovedDelegationRequests] = useState<UserEmployerAccessRequest[]>([]);

  const { data, loading, error } = useRequest<UserEmployerAccessRequestsData>(ExpenseService.getDelegationRequests);

  const setDefaultSelection = useCallback(
    (approvedRequests: UserEmployerAccessRequest[]) => {
      if (approvedRequests?.length > 0 && approvedRequests[0].approverId) {
        setSelectedDelegatorId(approvedRequests[0].approverId);
        setLocalStorageValue(EXPENSE_DELEGATOR_ID, approvedRequests[0].approverId);
        onChange(approvedRequests[0].approverId);
        onLoad(approvedRequests[0].approverId);
      } else {
        clearLocalStorage();
      }
    },
    [onChange, onLoad]
  );

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    const approvedRequests = filterApprovedRequests(data);

    if (approvedRequests.length === 0) {
      clearLocalStorage();
      onLoad('');
      return;
    }

    setApprovedDelegationRequests(approvedRequests);
    const delegatorIdLocalStorage = getLocalStorageValue(EXPENSE_DELEGATOR_ID) as string;

    if (delegatorIdLocalStorage) {
      const selectedDelegator = getSelectedDelegator(approvedRequests, delegatorIdLocalStorage);
      if (selectedDelegator) {
        setSelectedDelegatorId(delegatorIdLocalStorage);
        onLoad(delegatorIdLocalStorage);
      } else {
        clearLocalStorage();
        setDefaultSelection(approvedRequests);
      }
    } else {
      setDefaultSelection(approvedRequests);
    }
  }, [data, loading, onChange, onLoad, setDefaultSelection]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const delegatorId = event.target.value;

      setSelectedDelegatorId(delegatorId);
      setLocalStorageValue(EXPENSE_DELEGATOR_ID, delegatorId);

      onChange(delegatorId);
    },
    [onChange]
  );

  return approvedDelegationRequests.length > 0 ? (
    <>
      <TextField label={'Expense Report Owner'} select={true} value={selectedDelegatorId} onChange={handleChange}>
        {approvedDelegationRequests.map(({ approverId, name }) => (
          <MenuItem value={approverId} key={`select-delegator-${approverId}`}>
            {name}
          </MenuItem>
        ))}
      </TextField>

      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {error}
        </Alert>
      )}
    </>
  ) : null;
};

export default DelegationSelectList;
