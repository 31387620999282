import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import classNames from 'classnames';
import DelegationSelectList from 'components/expense/ExpenseReportCollectionPage/DelegationSelectList';
import useExpenseReportPrint from 'components/expense/ExpenseReportCollectionPage/ExpenseReportPrintSection/PrintExpenseReportHook';
import ExpenseReportTable from 'components/expense/ExpenseReportCollectionPage/ExpenseReportTable';
import reducer, {
  ExpenseReportTableAction,
  ExpenseReportTableActionType,
  ExpenseReportTableState,
  initialState,
} from 'components/expense/ExpenseReportCollectionPage/ExpenseReportTable/ExpenseReportTableReducer';
import DeleteReportModal from 'components/expense/ExpenseReportPage/DeleteReportModal';
import { ParametersContext } from 'components/ParametersGuard';
import FilterToggleGroup from 'components/shared/FilterToggleGroup';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ExpenseService from 'services/api/ExpenseService';
import routes from 'store/configs/Routes';
import ExpenseReportTableType from 'store/enums/ExpenseReportTableType';
import PaymentMethodType from 'store/enums/PaymentMethodType';
import { ExpenseReport } from 'store/types/Expense';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';
import { EXPENSE_DELEGATOR_ID, removeLocalStorageValue } from 'util/LocalStorage';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface ReportCollectionSectionProps {
  isDelegationTabSelected: boolean;
}

const ExpenseReportCollectionSection: React.FunctionComponent<ReportCollectionSectionProps> = ({
  isDelegationTabSelected,
}) => {
  const [
    {
      listDraftStatus,
      listSubmittedStatus,
      modalDeleteReportOpen,
      printedReport,
      selectedReportId,
      statuses,
      keyTableDraft,
      keyTableSubmitted,
    },
    dispatch,
  ] = useReducer<Reducer<ExpenseReportTableState, ExpenseReportTableAction>>(reducer, initialState);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [finishedLoadingDelegator, setFinishedLoadingDelegatorDelegator] = useState(false);
  const [delegatorIdState, setDelegatorIdState] = useState('');

  const {
    expenseReport: { reportsTableHeader, reportsTableHeaderDraftStatus },
  } = useContext(ParametersContext);

  const expenseReportsRequest = useCallback(async () => {
    let expenseReportsData: ExpenseReport[] = [];

    if (isDelegationTabSelected) {
      if (delegatorIdState) {
        expenseReportsData = await ExpenseService.getAllExpenseReportsDelegation(delegatorIdState);
      }
    } else {
      expenseReportsData = await ExpenseService.getAllExpenseReports();
    }

    return expenseReportsData;
  }, [delegatorIdState, isDelegationTabSelected]);

  /**
   * 1. Get expense reports for: (a) authenticated user if tab 1 selected; (b) delegator if tab 2 selected.
   * 2. IF has expense reports OR delegation tab is selected THEN load page.
   * 3. ELSE check if profile is complete. IF complete THEN load page, ELSE redirect to profile page.
   */
  const pageLoadRequest = useCallback(async () => {
    const expenseReportsData = await expenseReportsRequest();

    const hasExpenseReports = expenseReportsData?.length > 0;

    if (hasExpenseReports || isDelegationTabSelected) {
      return expenseReportsData ?? [];
    }

    const profileData = await ExpenseService.getExpenseProfile();
    const hasCompletedCheck = profileData?.paymentMethodType === PaymentMethodType.Check;
    const hasCompletedAch =
      profileData?.paymentMethodType === PaymentMethodType.Ach &&
      profileData?.achPayment?.accountNumber &&
      profileData?.achPayment?.routingCode;
    const hasCompletedProfile = hasCompletedCheck || hasCompletedAch;

    if (hasCompletedProfile) {
      return expenseReportsData;
    }

    // Expense profile does not exist, so redirect to profile page.
    history.push(routes.expenseProfile);

    return expenseReportsData;
  }, [expenseReportsRequest, history, isDelegationTabSelected]);

  const { data, error, loading, refetch } = useRequest<ExpenseReport[]>(pageLoadRequest);

  useEffect(() => {
    if (!isDelegationTabSelected) {
      removeLocalStorageValue(EXPENSE_DELEGATOR_ID);
    }

    dispatch({
      type: ExpenseReportTableActionType.SetInitialList,
      payload: { initialList: data },
    });
  }, [data, isDelegationTabSelected]);

  const showMissingDelegatorAlert = useMemo(
    () => !loading && finishedLoadingDelegator && isDelegationTabSelected && !delegatorIdState,
    [delegatorIdState, finishedLoadingDelegator, isDelegationTabSelected, loading]
  );

  const handlePrintFinished = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.SetPrintedReport,
      payload: {},
    });
  }, []);

  const [printExpenseReport, printExpenseReportComponent] = useExpenseReportPrint(printedReport, handlePrintFinished);

  useEffect(() => {
    if (printedReport) {
      printExpenseReport();
    }
  }, [printExpenseReport, printedReport]);

  const handlePrint = useCallback(
    (expenseReportId: string) => {
      ExpenseService.getSingleExpenseReport(expenseReportId)
        .then((report: ExpenseReport) => {
          dispatch({
            type: ExpenseReportTableActionType.SetPrintedReport,
            payload: { printedReport: report },
          });
        })
        .catch((errorMessage: string) => {
          dispatch({
            type: ExpenseReportTableActionType.SetPrintedReport,
            payload: {},
          });
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar]
  );

  const handleAddReport = useCallback(() => {
    history.push(`${routes.expenseReport}/new`);
  }, [history]);

  const handleConfirmModalDeleteOpen = useCallback((expenseReportId: string) => {
    dispatch({
      type: ExpenseReportTableActionType.SetSelectedReportId,
      payload: { selectedReportId: expenseReportId },
    });
    dispatch({
      type: ExpenseReportTableActionType.ToggleDeleteReportModal,
      payload: {},
    });
  }, []);

  const handleConfirmDeleteModalClose = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.ToggleDeleteReportModal,
      payload: {},
    });
  }, []);

  const handleDeleteReportModalSubmit = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.SetSelectedReportId,
      payload: { selectedReportId: '' },
    });
    dispatch({
      type: ExpenseReportTableActionType.ToggleDeleteReportModal,
      payload: {},
    });
    refetch();
  }, [refetch]);

  const handleFilter = useCallback((selectedStatusFilters: string[]) => {
    dispatch({
      type: ExpenseReportTableActionType.UpdateFilter,
      payload: { selectedStatusFilters: selectedStatusFilters },
    });
  }, []);

  const handleDelegatorChange = useCallback(
    (delegatorId: string) => {
      setDelegatorIdState(delegatorId);
      refetch();
    },
    [refetch]
  );

  const handleDelegatorLoad = useCallback(
    (delegatorId: string) => {
      setDelegatorIdState(delegatorId);
      refetch();
      setFinishedLoadingDelegatorDelegator(true);
    },
    [refetch]
  );

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Spinner loading={(isDelegationTabSelected && !finishedLoadingDelegator) || loading} transparent={true}>
      <Grid
        {...defaultGridContainerProps}
        spacing={2}
        className={classNames(styles.pageTitleSection, styles.alignBottom)}
      >
        {showMissingDelegatorAlert && (
          <Alert severity="warning" className={styles.alert}>
            <p>You do not have any approved delegation requests.</p>

            <p>
              If you wish to submit an expense report on behalf of someone else, please submit an{' '}
              <Link to={'../delegation'}>ACCESS REQUEST</Link> to that individual.
            </p>
          </Alert>
        )}

        <Grid {...defaultGridItemProps} md={6}>
          {isDelegationTabSelected && (
            <DelegationSelectList onChange={handleDelegatorChange} onLoad={handleDelegatorLoad} />
          )}
        </Grid>

        {delegatorIdState && finishedLoadingDelegator && (
          <Grid {...defaultGridItemProps} md={6} className={styles.createReportButton}>
            <Button color={'primary'} variant={'contained'} onClick={handleAddReport}>
              {'Submit an Expense Report'}
            </Button>
          </Grid>
        )}
      </Grid>

      <h2 className={styles.tableTitle}>{'Draft Expense Reports'}</h2>

      {reportsTableHeaderDraftStatus && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsTableHeaderDraftStatus }} />
      )}

      <div className={styles.contentBorder}>
        {!loading && listDraftStatus && (
          <ExpenseReportTable
            key={keyTableDraft}
            data={listDraftStatus}
            tableType={ExpenseReportTableType.ReportDraft}
            onModalDeleteOpen={handleConfirmModalDeleteOpen}
          />
        )}
      </div>

      <h2 className={styles.tableTitle}>{'Submitted Expense Reports'}</h2>

      {reportsTableHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsTableHeader }} />
      )}

      {statuses?.length > 1 && (
        <div className={styles.contentBlock}>
          <FilterToggleGroup items={statuses} onChange={handleFilter} />
        </div>
      )}

      <div className={styles.contentBorder}>
        {!loading && listSubmittedStatus && (
          <ExpenseReportTable
            key={keyTableSubmitted}
            data={listSubmittedStatus}
            tableType={ExpenseReportTableType.ReportSubmitted}
            onPrint={handlePrint}
          />
        )}
      </div>

      <DeleteReportModal
        expenseReportId={selectedReportId}
        open={modalDeleteReportOpen}
        onSubmit={handleDeleteReportModalSubmit}
        onClose={handleConfirmDeleteModalClose}
      />

      {printExpenseReportComponent}
    </Spinner>
  );
};

export default ExpenseReportCollectionSection;
