import React from 'react';
import { Button } from '@mui/material';
import Modal from 'components/shared/Modal';
import { ModalProps } from 'store/types/ComponentProps';

import commonStyles from 'styles/common.module.scss';

interface DeleteExpenseModalProps extends ModalProps {
  onSubmit: () => void;
}

const ConfirmSubmitReportModal: React.FunctionComponent<DeleteExpenseModalProps> = ({ open, onClose, onSubmit }) => {
  return (
    <Modal
      title={'Confirm expense report submission'}
      open={open}
      onClose={onClose}
      disableBackdropClick={false}
      maxWidth={'sm'}
      actions={
        <>
          <Button color={'secondary'} variant={'outlined'} onClick={onClose}>
            {'Cancel'}
          </Button>
          <Button type={'submit'} color={'secondary'} variant={'contained'} onClick={onSubmit}>
            {'Submit for Approval'}
          </Button>
        </>
      }
    >
      <p className={commonStyles.text}>
        You are about to submit an expense report for approval. Are you sure you wish to continue?
      </p>
    </Modal>
  );
};

export default ConfirmSubmitReportModal;
