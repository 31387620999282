import Alert from '@mui/material/Alert';
import useRequest from 'hooks/useRequest';
import React, { useContext } from 'react';
import ChapterService from 'services/api/ChapterService';
import SiteModule from 'store/enums/SiteModule';
import { getPageTitle } from 'util/Layout';
import { ConfigContext } from 'components/ConfigGuard';
import ChaptersPageView from './ChaptersPageView';
import { ChapterCustomer } from 'store/types/Chapter';

import commonStyles from 'styles/common.module.scss';

const ChaptersPage: React.FunctionComponent = () => {
  const { sidebar } = useContext(ConfigContext);
  const { data, loading, error } = useRequest<ChapterCustomer[]>(ChapterService.getMyChapterCustomers);

  return (
    <>
      <h1 className={commonStyles.pageTitle}>{getPageTitle(sidebar, SiteModule.Chapters) || 'Chapters'}</h1>
      {error && (
        <Alert severity={'error'} className={commonStyles.alert}>
          {' '}
          {error}{' '}
        </Alert>
      )}
      {data && <ChaptersPageView data={data} loading={loading} />}
    </>
  );
};
export default ChaptersPage;
