import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { HttpService } from 'services/HttpService';
import {
  AchPaymentCreateRequest,
  AchPaymentUpdateRequest,
  ExpenseCategory,
  ExpenseProfile,
  ExpenseReport,
  ExpenseReportCreateRequest,
  ExpenseReportItemCreateRequest,
  ExpenseReportItemUpdateRequest,
  ExpenseReportUpdateRequest,
  PaymentMethodTypeUpdateRequest,
} from 'store/types/Expense';
import { httpRequestWrapper } from 'util/Request';
import { UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';

class ExpenseService {
  public static getExpenseProfile(): Promise<ExpenseProfile> {
    return new Promise<ExpenseProfile>((resolve: (data: ExpenseProfile) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseProfile>(
        HttpService.get(`${API_URL}/expense/profile`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updatePaymentMethodType(data: PaymentMethodTypeUpdateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/profile/payment-method-type`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createAchPayment(data: AchPaymentCreateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/profile/ach`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateAchPayment(data: AchPaymentUpdateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/profile/ach`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getAllExpenseReports(): Promise<ExpenseReport[]> {
    return new Promise<ExpenseReport[]>((resolve: (data: ExpenseReport[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseReport[]>(
        HttpService.get(`${API_URL}/expense/reports`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getAllExpenseReportsDelegation(delegatorId: string): Promise<ExpenseReport[]> {
    return new Promise<ExpenseReport[]>((resolve: (data: ExpenseReport[]) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseReport[]>(
        HttpService.get(`${API_URL}/expense/reports`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          queryParams: {
            delegatorId,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getSingleExpenseReport(id: string): Promise<ExpenseReport> {
    return new Promise<ExpenseReport>((resolve: (data: ExpenseReport) => void, reject: (error: string) => void) => {
      httpRequestWrapper<ExpenseReport>(
        HttpService.get(`${API_URL}/expense/reports/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createExpenseReport(data: ExpenseReportCreateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/reports`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateExpenseReport(expenseReportId: string, data: ExpenseReportUpdateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/reports/${expenseReportId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteExpenseReport(expenseReportId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/expense/reports/${expenseReportId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static createExpenseItem(data: ExpenseReportItemCreateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/expense/item`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static updateExpenseItem(expenseItemId: string, data: ExpenseReportItemUpdateRequest): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/item/${expenseItemId}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteExpenseItem(expenseReportId: string, expenseId: string, delegatorId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/expense/reports/${expenseReportId}/item/${expenseId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          queryParams: {
            delegatorId: delegatorId,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getTaxFile(fileId: string, filename: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/expense/profile/tax-file/${fileId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: filename,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadTaxFile(file: File): Promise<string> {
    const data = new FormData();
    data.append('file', file);

    return new Promise<string>((resolve: (data: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/expense/profile/tax-file`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteTaxFile(fileId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/expense/profile/tax-file/${fileId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getReceiptFile(expenseReportId: string, expenseId: string, filename: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/expense/reports/${expenseReportId}/expense/${expenseId}/receipt-file`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: filename,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static uploadReceiptFile(file: File, expenseReportId?: string): Promise<string> {
    const data = new FormData();
    data.append('file', file);

    return new Promise<string>((resolve: (data: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper<string>(
        HttpService.post(`${API_URL}/expense/reports/receipt-file/${expenseReportId ?? ''}`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getExpenseCategories(): Promise<ExpenseCategory[]> {
    return new Promise<ExpenseCategory[]>(
      (resolve: (data: ExpenseCategory[]) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ExpenseCategory[]>(
          HttpService.get(`${API_URL}/expense/categories`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static createVendor(): Promise<string> {
    return new Promise<string>((resolve: (value: string) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/expense/vendor`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getDelegationRequests(): Promise<UserEmployerAccessRequestsData> {
    return new Promise<UserEmployerAccessRequestsData>(
      (resolve: (data: UserEmployerAccessRequestsData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<UserEmployerAccessRequestsData>(
          HttpService.get(`${API_URL}/expense/delegation`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static createDelegationRequest(approverId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/expense/delegation/${approverId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static cancelDelegationRequest(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.put(`${API_URL}/expense/delegation/cancel/${requestId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static rejectDelegationRequest(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/expense/delegation/reject/${requestId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static approveDelegationRequest(requestId: string): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.put(`${API_URL}/expense/delegation/approve/${requestId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}

export default ExpenseService;
