import React, { Reducer, useCallback, useContext, useReducer } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import DelegationRequestModal from 'components/expense/ExpenseProfilePage/ExpenseDelegationSection/DelegationRequestModal';
import ExpenseDelegationTable from 'components/expense/ExpenseProfilePage/ExpenseDelegationSection/ExpenseDelegationTable';
import reducer, {
  ExpenseReportTableAction,
  ExpenseReportTableActionType,
  ExpenseReportTableState,
  initialState,
} from 'components/expense/ExpenseReportCollectionPage/ExpenseReportTable/ExpenseReportTableReducer';
import { ParametersContext } from 'components/ParametersGuard';
import Spinner from 'components/shared/Spinner';
import useRequest from 'hooks/useRequest';
import ExpenseService from 'services/api/ExpenseService';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { UserEmployerAccessRequest, UserEmployerAccessRequestsData } from 'store/types/UserEmployerAccessRequest';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface ExpenseDelegationSectionProps {
  profileId: string;
}

/**
 * Checks outgoing requests for those that are either active or pending and then returns an array of those customer IDs.
 * These results are used for disabling those individuals in the search results to prevent duplicate access requests.
 * @returns {string[]} Customer IDs
 */
const getIdsToDisable = (profileId: string, requestData: UserEmployerAccessRequestsData | undefined) => {
  if (!requestData || requestData?.outgoing?.length === 0) {
    return [profileId];
  }

  const statuses = [
    CompanyAccessRequestStatus.Active,
    CompanyAccessRequestStatus.Approved,
    CompanyAccessRequestStatus.Pending,
  ];

  const filteredItems = requestData.outgoing.filter((item) => statuses.includes(item.status));

  return filteredItems.reduce((acc: string[], currentValue: UserEmployerAccessRequest) => {
    if (acc.length === 0) {
      acc.push(profileId);
    }

    if (currentValue?.approverId && !acc.includes(currentValue.approverId)) {
      acc.push(currentValue.approverId);
    }
    return acc;
  }, []);
};

const ExpenseDelegationSection: React.FunctionComponent<ExpenseDelegationSectionProps> = ({ profileId }) => {
  const [{ modalDelegationOpen }, dispatch] = useReducer<Reducer<ExpenseReportTableState, ExpenseReportTableAction>>(
    reducer,
    initialState
  );

  const {
    expenseProfile: { delegationTabHeader, delegationTableHeader, delegationTableHeaderSentStatus },
  } = useContext(ParametersContext);

  const { data, error, loading, refetch } = useRequest<UserEmployerAccessRequestsData>(
    ExpenseService.getDelegationRequests
  );

  const handleChange = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleToggleModal = useCallback(() => {
    dispatch({
      type: ExpenseReportTableActionType.ToggleDelegationModal,
      payload: {},
    });

    refetch();
  }, [refetch]);

  return error ? (
    <Alert severity={'error'} className={commonStyles.alert}>
      {error}
    </Alert>
  ) : (
    <Spinner loading={loading} transparent={true}>
      {delegationTabHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: delegationTabHeader }} />
      )}

      <Grid {...defaultGridContainerProps} spacing={0} className={styles.pageTitleSection}>
        <Grid {...defaultGridItemProps} md={6}>
          <h2 className={styles.tableTitle}>{'Expense Report Access Requests'}</h2>
        </Grid>
        <Grid {...defaultGridItemProps} md={6} className={styles.pullRight}>
          <Button color={'primary'} variant={'contained'} onClick={handleToggleModal}>
            {'Request Access'}
          </Button>
        </Grid>
      </Grid>

      {delegationTableHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: delegationTableHeader }} />
      )}

      {!loading && data && (
        <ExpenseDelegationTable data={data?.incoming ?? []} isSentRequest={false} onChange={handleChange} />
      )}

      <h2 className={styles.tableTitle}>{'Expense Report Access Requests Sent'}</h2>

      {delegationTableHeaderSentStatus && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: delegationTableHeaderSentStatus }} />
      )}

      {!loading && data && (
        <ExpenseDelegationTable data={data?.outgoing ?? []} isSentRequest={true} onChange={handleChange} />
      )}

      <DelegationRequestModal
        open={modalDelegationOpen}
        onClose={handleToggleModal}
        disabledUserIds={getIdsToDisable(profileId, data)}
      />
    </Spinner>
  );
};

export default ExpenseDelegationSection;
