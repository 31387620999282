import { Grid } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import PageCard from 'components/shared/PageCard';
import ComponentProps from 'store/types/ComponentProps';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import CommunityInfoSection from 'components/communities/CommunityInfoSection';
import CommunityFilesSection from 'components/communities/CommunityFilesSection';
import ChapterService from 'services/api/ChapterService';
import { hasChapterAdminRole } from 'util/User';
import { ChapterCustomer } from 'store/types/Chapter';
import ChapterReportsSection from 'components/communities/ChapterReportsSection';
import useRequest from 'hooks/useRequest';
import { ConfigContext } from 'components/ConfigGuard';
import ChapterMembersSection from 'components/communities/ChapterMembersSection';

import styles from './ChapterDetailsCard.module.scss';

interface ChapterDetailsCardProps extends ComponentProps {
  chapterCustomer: ChapterCustomer;
}

const fileRequests = {
  uploadFileRequest: ChapterService.uploadChapterFile,
  downloadFileRequest: ChapterService.getChapterFile,
  deleteFileRequest: ChapterService.deleteChapterFile,
};

// const eventsRequests = {
//   createEventRequest: ChapterService.createChapterEvent,
//   updateEventRequest: ChapterService.updateChapterEvent,
//   deleteEventRequest: ChapterService.deleteChapterEvent,
//   downloadEventFileRequest: ChapterService.getChapterEventFile,
// };

const ChapterDetailsCard: React.FunctionComponent<ChapterDetailsCardProps> = ({ chapterCustomer, className = '' }) => {
  const detailsRequestFn = useCallback(
    () => ChapterService.getChapterDetails(chapterCustomer.chapter.id),
    [chapterCustomer]
  );
  const detailsRequest = useRequest(detailsRequestFn);
  const { data: detailsData, loading } = detailsRequest;
  const allFiles = detailsData?.files ?? [];

  const { isAcsTheme } = useContext(ConfigContext);
  const hasAdminRole: boolean = useMemo(() => hasChapterAdminRole(chapterCustomer), [chapterCustomer]);

  const infoSection: React.ReactNode = <CommunityInfoSection data={chapterCustomer} />;

  // const eventsSection: React.ReactNode = (
  //   <CommunityEventsSection
  //     {...detailsRequest}
  //     data={detailsData ? detailsData.events : undefined}
  //     requests={eventsRequests}
  //     communityId={id}
  //     editEnabled={hasAdminRole}
  //   />
  // );
  const filesSection: React.ReactNode = (
    <CommunityFilesSection
      {...detailsRequest}
      data={allFiles}
      communityId={chapterCustomer.chapter.id}
      editEnabled={hasAdminRole}
      description={'Documentation uploaded by Chapter Chairs.'}
      requests={fileRequests}
    />
  );
  const membersSection: React.ReactNode = (
    <ChapterMembersSection
      data={detailsData ?? {}}
      downloadRosterEnabled={hasAdminRole}
      extendedTableView={hasAdminRole}
      description={isAcsTheme ? 'The list of dues paying chapter members.' : 'The list of chapter members.'}
      loading={loading}
    />
  );

  return (
    <PageCard title={name} className={className} headerClassName={styles.cardHeader}>
      <Grid {...defaultGridContainerProps} className={styles.container}>
        {hasAdminRole ? (
          <>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>
                  <ChapterReportsSection data={chapterCustomer.chapter} />
                </Grid>
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              {infoSection}
            </Grid>
            <Grid {...defaultGridItemProps}>{membersSection}</Grid>
            {/*Hidden due PBI-18379-Hide entire upcoming meetings section from Chapters and Committees*/}
            {/*<Grid {...defaultGridItemProps} md={6}>*/}
            {/*  {eventsSection}*/}
            {/*</Grid>*/}
            <Grid {...defaultGridItemProps} md={6}>
              {filesSection}
            </Grid>
          </>
        ) : (
          <>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>{infoSection}</Grid>
                {/*Hidden due PBI-18379-Hide entire upcoming meetings section from Chapters and Committees*/}
                {/*<Grid {...defaultGridItemProps}>{eventsSection}</Grid>*/}
              </Grid>
            </Grid>
            <Grid {...defaultGridItemProps} md={6}>
              <Grid {...defaultGridContainerProps}>
                <Grid {...defaultGridItemProps}>{filesSection}</Grid>
                <Grid {...defaultGridItemProps}>{membersSection}</Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </PageCard>
  );
};
export default ChapterDetailsCard;
