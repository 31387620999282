import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import TaxFileTable from 'components/expense/ExpenseProfilePage/TaxFilesSection/TaxFileTable';
import TaxUploadModal from 'components/expense/ExpenseProfilePage/TaxFilesSection/TaxUploadModal';
import { ParametersContext } from 'components/ParametersGuard';
import Modal from 'components/shared/Modal';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import ExpenseService from 'services/api/ExpenseService';
import { ExpenseFile } from 'store/types/Expense';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface TaxFilesSectionProps {
  data: ExpenseFile[];
  refetch: () => void;
}

const TaxFilesSection: React.FunctionComponent<TaxFilesSectionProps> = ({ data = [], refetch }) => {
  const {
    modulesSettings: {
      expenseProfile: { profileTaxFilesTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseProfile: { profileTaxDocumentHeader },
  } = useContext(ParametersContext);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modalConfirmDeleteOpen, setModalConfirmDeleteOpen] = useState(false);
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [fileId, setFileId] = useState('');

  const handleConfirmDeleteModalClose = () => setModalConfirmDeleteOpen(false);
  const handleUploadModalOpen = () => setModalUploadOpen(true);
  const handleUploadModalClose = () => setModalUploadOpen(false);

  const handleDownload = useCallback(
    (fileId, filename) => {
      setLoading(true);

      ExpenseService.getTaxFile(fileId, filename)
        .then(() => {
          setLoading(false);
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar]
  );

  const handleConfirmDelete = useCallback((fileId) => {
    setFileId(fileId);
    setModalConfirmDeleteOpen(true);
  }, []);

  const handleDelete = useCallback(() => {
    setLoading(true);

    ExpenseService.deleteTaxFile(fileId)
      .then(() => {
        setLoading(false);
        setModalConfirmDeleteOpen(false);
        refetch();
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar, fileId, refetch]);

  return (
    <Spinner loading={loading} transparent={true}>
      <div className={styles.contentBlock}>
        <Grid {...defaultGridContainerProps} spacing={0} className={styles.pageTitleSection}>
          <Grid {...defaultGridItemProps} md={8}>
            <h2>{profileTaxFilesTitle}</h2>
          </Grid>

          <Grid {...defaultGridItemProps} md={4} className={styles.pullRight}>
            <Button color={'primary'} variant={'contained'} onClick={handleUploadModalOpen}>
              {'Upload Documents'}
            </Button>
          </Grid>
        </Grid>

        {profileTaxDocumentHeader && (
          <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: profileTaxDocumentHeader }} />
        )}

        <TaxFileTable data={data} onDownload={handleDownload} onDelete={handleConfirmDelete} />
      </div>

      <TaxUploadModal open={modalUploadOpen} onClose={handleUploadModalClose} refetch={refetch} />

      <Modal
        title={'Delete Tax Document'}
        open={modalConfirmDeleteOpen}
        onClose={handleConfirmDeleteModalClose}
        disableBackdropClick={false}
        maxWidth={'sm'}
        loading={loading}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleConfirmDeleteModalClose} disabled={loading}>
              {'Cancel'}
            </Button>
            <Button
              type={'submit'}
              variant={'contained'}
              className={commonStyles.dangerButtonContained}
              disabled={loading}
              onClick={handleDelete}
            >
              {'Delete'}
            </Button>
          </>
        }
      >
        <p className={commonStyles.text}>You are about to delete a tax document.</p>
      </Modal>
    </Spinner>
  );
};

export default TaxFilesSection;
