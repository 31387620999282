import { FilterConfig, SortConfig } from 'store/types/Table';
import { sorter, userFullNameSortLabel } from 'util/Table';
import { ChapterCustomer } from 'store/types/Chapter';
import CommunityMember from 'store/types/CommunityMember';
import { getUserFullName } from 'util/Format';

export enum ChapterMembersSectionActionType {
  SetInitialList = 'SetInitialList',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
  OpenDetails = 'OpenDetails',
  CloseDetails = 'CloseDetails',
}

export type TableChapterCustomerUser = ChapterCustomer & { [userFullNameSortLabel]?: string; year?: string };

export interface ChapterMembersSectionState {
  initialList: ChapterCustomer[];
  list: ChapterCustomer[];
  sort: SortConfig<TableChapterCustomerUser>;
  filter: FilterConfig<TableChapterCustomerUser>;
  clickedItem?: ChapterCustomer;
}

export interface ChapterMembersSectionAction {
  type: ChapterMembersSectionActionType;
  payload: Partial<ChapterMembersSectionState>;
}

const getSortedList = (
  list: TableChapterCustomerUser[] = [],
  sort: SortConfig<TableChapterCustomerUser>
): TableChapterCustomerUser[] => {
  let mutableList = [...list];
  if (sort.column === userFullNameSortLabel) {
    mutableList = list.length
      ? mutableList.sort((a, b) => {
          const firstNameAValue = a?.customer?.firstName ? a.customer?.firstName : '';
          const lastNameAValue = a?.customer?.lastName ? a.customer?.lastName : '';
          const firstNameBValue = b?.customer?.firstName ? b.customer?.firstName : '';
          const lastNameBValue = b?.customer?.lastName ? b.customer?.lastName : '';
          const ascLastNameResult = lastNameAValue.toLowerCase().localeCompare(lastNameBValue.toLowerCase());
          const ascFirstNameResult = firstNameAValue.toLowerCase().localeCompare(firstNameBValue.toLowerCase());

          if (ascFirstNameResult !== 0) {
            return sort.direction === 'asc' ? ascFirstNameResult : -ascFirstNameResult;
          } else {
            return sort.direction === 'asc' ? ascLastNameResult : -ascLastNameResult;
          }
        })
      : mutableList;
  }
  if (sort.column === 'role') {
    mutableList = list.length
      ? mutableList.sort((a, b) => {
          const roleCompareResult = a?.role?.name.localeCompare(b.role?.name);
          if (roleCompareResult !== 0) {
            return sort.direction === 'asc' ? roleCompareResult : -roleCompareResult;
          }
          return 0;
        })
      : mutableList;
  }
  return list.length ? mutableList.sort(sorter<TableChapterCustomerUser>(sort)) : mutableList;
};

const getFilteredList = (list: TableChapterCustomerUser[] = [], filter: FilterConfig<CommunityMember>) => {
  const searchValue = filter.search?.toLowerCase();
  let result = [...list];

  if (filter.role.length) {
    result = result.filter((chapterCustomer) => filter.role?.includes(chapterCustomer.role?.name));
  }
  if (searchValue) {
    result = result.filter((chapterCustomer) => {
      if (!chapterCustomer?.customer?.firstName || !chapterCustomer?.customer?.lastName) {
        return false;
      }
      const { firstName, lastName } = chapterCustomer.customer;
      return getUserFullName({ firstName, lastName }).toLowerCase().includes(searchValue);
    });
  }
  if (filter.year) {
    result = result.reduce((acc: TableChapterCustomerUser[], currentValue: TableChapterCustomerUser) => {
      if (currentValue?.startDate && new Date(currentValue.startDate).getFullYear() > filter.year) {
        return acc;
      }

      if (currentValue?.endDate && new Date(currentValue.endDate).getFullYear() < filter.year) {
        return acc;
      }

      acc.push(currentValue);

      return acc;
    }, []);
  }
  return result;
};

export const initialState: ChapterMembersSectionState = {
  initialList: [],
  list: [],
  sort: { direction: 'asc' },
  filter: { search: '', role: [], year: new Date().getFullYear() },
};

const reducer = (
  state: ChapterMembersSectionState,
  { type, payload }: ChapterMembersSectionAction
): ChapterMembersSectionState => {
  if (type === ChapterMembersSectionActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return { ...state, initialList, list: getFilteredList(getSortedList(initialList, state.sort), state.filter) };
  }

  if (type === ChapterMembersSectionActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.list, sort),
    };
  }

  if (type === ChapterMembersSectionActionType.UpdateFilter) {
    const { filter = {} } = payload;
    const newFilter = { ...state.filter, ...filter };

    return {
      ...state,
      filter: newFilter,
      list: getFilteredList(getSortedList(state.initialList, state.sort), newFilter),
    };
  }

  if (type === ChapterMembersSectionActionType.OpenDetails) {
    const { clickedItem } = payload;

    return { ...state, clickedItem };
  }

  if (type === ChapterMembersSectionActionType.CloseDetails) {
    return { ...state, clickedItem: undefined };
  }

  return state;
};

export default reducer;
