import { Button, Checkbox, Grid, ListItemText, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { SortConfig, TableColumn } from 'store/types/Table';
import Table from 'components/shared/Table';
import Card from 'components/shared/Card';
import SearchInput from 'components/shared/SearchInput';
import { getCountryName, getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import { defaultGridItemProps, defaultSnackbarErrorProps, getButtonLoadingProps } from 'util/Layout';
import TableCountLabel from 'components/shared/TableCountLabel';
import Address from 'store/types/Address';
import { userFullNameSortLabel } from 'util/Table';
import reducer, {
  ChapterMembersSectionAction,
  ChapterMembersSectionActionType,
  ChapterMembersSectionState,
  initialState,
  TableChapterCustomerUser,
} from './ChapterMembersSectionReducer';
import { ConfigContext } from 'components/ConfigGuard';
import { multipleSelectProps } from 'util/Form';
import SelectOption from 'store/types/SelectOption';
import { ChapterDetails } from 'store/types/Chapter';
import ComponentProps from 'store/types/ComponentProps';
import ChapterMemberModal from '../ChapterMemberModal';
import chapterService from 'services/api/ChapterService';

import pageStyles from '../CommunityDetailsSection.module.scss';
import styles from './ChapterMembersSection.module.scss';

interface CommunityMembersSectionProps extends ComponentProps {
  data: ChapterDetails;
  extendedTableView?: boolean;
  downloadRosterEnabled?: boolean;
  description: string;
  loading: boolean;
}

export const getAddressString = (address?: Address): string => {
  if (address) {
    const { city, country, stateRegion } = address;
    const countryName = getCountryName(country) || '';

    return city ? `${city}, ${stateRegion || countryName}` : countryName;
  } else {
    return '';
  }
};

const ChapterMembersSection: React.FunctionComponent<CommunityMembersSectionProps> = ({
  data,
  extendedTableView,
  downloadRosterEnabled,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAcsTheme } = useContext(ConfigContext);
  const [{ sort, list, initialList, clickedItem, filter }, dispatch] = useReducer<
    Reducer<ChapterMembersSectionState, ChapterMembersSectionAction>
  >(reducer, initialState);
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      dispatch({
        type: ChapterMembersSectionActionType.SetInitialList,
        payload: { initialList: data.members },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: ChapterMembersSectionActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleProfileButtonClick = useCallback(
    (user: TableChapterCustomerUser) => () => {
      dispatch({
        type: ChapterMembersSectionActionType.OpenDetails,
        payload: { clickedItem: user },
      });
    },
    []
  );

  const handleDownloadClick = useCallback(() => {
    if (data.chapter) {
      setFileLoading(true);
      const selectedRoles: string[] =
        data.roles?.filter(({ name }) => filter.role.includes(name)).map(({ name }) => name) ?? [];

      chapterService
        .getChapterMembersRoster(data.chapter.id, selectedRoles, data.chapter.name)
        .then(() => {
          setFileLoading(false);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setFileLoading(false);
        });
    }
  }, [data.chapter, data.roles, enqueueSnackbar, filter.role]);

  const handleModalClose = useCallback(() => {
    dispatch({
      type: ChapterMembersSectionActionType.CloseDetails,
      payload: {},
    });
  }, []);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ChapterMembersSectionActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleRoleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ChapterMembersSectionActionType.UpdateFilter,
      payload: { filter: { role: e.target.value } },
    });
  }, []);

  const handleYearFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ChapterMembersSectionActionType.UpdateFilter,
      payload: { filter: { year: e.target.value } },
    });
  }, []);

  const yearFilterOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 9;

    return Array(endYear - currentYear + 1)
      .fill('')
      .map((_, i) => currentYear + i);
  }, []);

  let columns: Array<TableColumn<TableChapterCustomerUser>> = [
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: TableChapterCustomerUser) =>
        `${record.customer?.firstName} ${record.customer?.lastName}`,
    },
    {
      dataIndex: 'role',
      label: 'Role',
      sortable: true,
      render: (_: any, record: TableChapterCustomerUser) => record.role?.name,
    },
  ];

  if (extendedTableView) {
    columns = [
      ...columns,
      {
        dataIndex: 'startDate',
        label: 'Start Date',
        sortable: true,
        render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
      },
      {
        dataIndex: 'endDate',
        label: 'End Date',
        sortable: true,
        render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
      },
    ];
  }
  columns.push({
    key: 'profile',
    label: 'Profile',
    align: 'center',
    render: (_: any, record: TableChapterCustomerUser) => (
      <Button
        size={'small'}
        variant={'outlined'}
        color={'primary'}
        className={pageStyles.sectionTableActionButton}
        onClick={handleProfileButtonClick(record)}
      >
        View
      </Button>
    ),
  });

  return (
    <>
      <Card
        bordered={true}
        title={<h3>Members</h3>}
        headerClassName={pageStyles.sectionHeader}
        headerAction={
          downloadRosterEnabled && (
            <Button
              color={'primary'}
              variant={'contained'}
              className={pageStyles.sectionCardActionButton}
              disabled={fileLoading}
              onClick={handleDownloadClick}
              size={'small'}
              {...getButtonLoadingProps(fileLoading)}
            >
              {isAcsTheme ? 'Download Results' : 'Download Roster'}
            </Button>
          )
        }
      >
        <>
          <Grid container={true} spacing={2}>
            {!!data.roles?.length && (
              <Grid {...defaultGridItemProps} md={4}>
                <TextField
                  {...multipleSelectProps(data.roles, 'Select Roles')}
                  onChange={handleRoleFilterChange}
                  value={filter.role}
                  size={'small'}
                  select={true}
                  InputProps={{ className: styles.selectRoleField }}
                >
                  {data.roles.map(({ id, name }: SelectOption) => (
                    <MenuItem value={name} key={`role-${id}`}>
                      <Checkbox checked={filter.role.includes(name)} color={'primary'} size={'small'} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid {...defaultGridItemProps} md={8}>
              <SearchInput placeholder={'Search Members'} value={filter.search} onChange={handleSearchChange} />
            </Grid>
            <Grid {...defaultGridItemProps} md={4}>
              <TextField label={'Filter by Year'} select={true} value={filter.year} onChange={handleYearFilterChange}>
                <MenuItem value={''}>
                  <em>All</em>
                </MenuItem>
                {yearFilterOptions.map((item) => (
                  <MenuItem value={item} key={`year-filter-${item}`}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {extendedTableView && (
              <Grid {...defaultGridItemProps}>
                <TableCountLabel
                  viewCount={list.length}
                  totalCount={initialList.length}
                  className={styles.countLabel}
                />
              </Grid>
            )}
          </Grid>
          <Table
            columns={columns}
            list={list}
            sort={sort}
            onSortChange={handleSortChange}
            className={pageStyles.sectionTable}
            showPagination={true}
            loading={loading}
          />
        </>
      </Card>
      {!!clickedItem && (
        <ChapterMemberModal chapterCustomer={clickedItem} open={!!clickedItem} onClose={handleModalClose} />
      )}
    </>
  );
};
export default ChapterMembersSection;
