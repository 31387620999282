import React from 'react';
import { getStringValue, STRING_DATE_FORMAT } from 'util/Format';
import Card from 'components/shared/Card';
import { JoinedCommunity } from 'store/types/Community';
import { ChapterCustomer } from 'store/types/Chapter';

import styles from './CommunityInfoSection.module.scss';

interface CommunityInfoSectionProps {
  data: JoinedCommunity | ChapterCustomer;
}

const isChapterCustomer = (data: JoinedCommunity | ChapterCustomer): data is ChapterCustomer => {
  return (data as ChapterCustomer).customer !== undefined;
};

const CommunityInfoSection: React.FunctionComponent<CommunityInfoSectionProps> = ({ data }) => {
  let role: string | undefined;
  let startDate: string | undefined;
  let description: string | undefined;

  if (isChapterCustomer(data)) {
    role = data.role.name;
    startDate = data.startDate?.toString();
    description = data.chapter.purpose;
  } else {
    role = data.member.role;
    startDate = data.member.startDate;
    description = data.description;
  }

  return (
    <Card bordered={true}>
      <section>
        <div className={styles.row}>
          <span className={styles.label}>{'About:'}</span>
          <span className={styles.value}>{description}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{'Role:'}</span>
          <span className={styles.value}>{role}</span>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{'Joined:'}</span>
          <span className={styles.value}>{getStringValue(startDate, STRING_DATE_FORMAT)}</span>
        </div>
      </section>
    </Card>
  );
};
export default CommunityInfoSection;
