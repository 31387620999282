import { Grid } from '@mui/material';
import Modal from 'components/shared/Modal';
import React, { useMemo } from 'react';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getCountryName, getEmailLink, getPhoneLink, getUserFullName } from 'util/Format';
import { ModalProps } from 'store/types/ComponentProps';
import Phone from '@mui/icons-material/Phone';
import Domain from '@mui/icons-material/Domain';
import Avatar from 'components/shared/Avatar';
import Address from 'store/types/Address';
import { Email, Language } from '@mui/icons-material';
import Home from '@mui/icons-material/Home';
import Website from 'components/shared/Website';
import { ChapterCustomer } from 'store/types/Chapter';

import styles from './ChapterMemberModal.module.scss';

interface ChapterMemberModalProps extends ModalProps {
  chapterCustomer: ChapterCustomer;
}

const ChapterMemberModal: React.FunctionComponent<ChapterMemberModalProps> = ({ open, chapterCustomer, onClose }) => {
  const email: string = chapterCustomer.customer.email;
  const address: Address | undefined = chapterCustomer.customer.address;
  const phone: string = chapterCustomer.customer.phone ?? '';
  const companyName: string = chapterCustomer.customer.company?.name ?? '';
  const website: string = chapterCustomer.customer.parentCompanyWebsite ?? '';
  const showPersonalInformationSection: boolean = useMemo(
    () => !!address || !!phone || !!email,
    [address, email, phone]
  );
  const showCompanyInformationSection: boolean = useMemo(() => !!companyName || !!website, [companyName, website]);

  return (
    <Modal disableBackdropClick={false} open={open} onClose={onClose} title={'Member Card'} maxWidth={'sm'}>
      <Grid {...defaultGridContainerProps}>
        <Grid
          {...defaultGridItemProps}
          sm={!showCompanyInformationSection && !showPersonalInformationSection ? 12 : 5}
          className={styles.personWrapper}
        >
          <div className={styles.person}>
            <Avatar src={chapterCustomer.customer.logoUrl ?? ''} className={styles.avatar} />
            <h4 className={styles.name}>{getUserFullName(chapterCustomer.customer)}</h4>
            <p className={styles.role}>{chapterCustomer.role?.name}</p>
          </div>
        </Grid>
        {(showCompanyInformationSection || showPersonalInformationSection) && (
          <Grid {...defaultGridItemProps} sm={7} className={styles.contactsWrapper}>
            {showPersonalInformationSection && (
              <>
                <h5 className={styles.subTitle}>{'Personal Information'}</h5>
                {address && (
                  <div className={styles.row}>
                    <Home className={styles.icon} />
                    <div className={styles.locationValue}>
                      <span className={styles.location}>{`${address.city}, ${
                        address.stateRegion ? `${address.stateRegion}` : ''
                      }`}</span>
                      <span className={styles.location}>{getCountryName(address.country)}</span>
                    </div>
                  </div>
                )}
                {phone && (
                  <div className={styles.row}>
                    <Phone className={styles.icon} />
                    {getPhoneLink(phone)}
                  </div>
                )}
                {email && (
                  <div className={styles.row}>
                    <Email className={styles.icon} />
                    {getEmailLink(email)}
                  </div>
                )}
              </>
            )}
            {showCompanyInformationSection && (
              <>
                <h5 className={styles.subTitle}>{'Company Information'}</h5>
                {companyName && (
                  <div className={styles.row}>
                    <Domain className={styles.icon} />
                    {companyName}
                  </div>
                )}
                {website && (
                  <div className={styles.row}>
                    <Language className={styles.icon} />
                    <Website link={website} />
                  </div>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
export default ChapterMemberModal;
