import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import Print from '@mui/icons-material/Print';
import { Button, Grid, IconButton } from '@mui/material';
import reducer, {
  ExpenseReportTableAction,
  ExpenseReportTableActionType,
  ExpenseReportTableState,
  initialState,
} from 'components/expense/ExpenseReportCollectionPage/ExpenseReportTable/ExpenseReportTableReducer';
import ShowMoreText from 'components/shared/ShowMoreText';
import StatusLabel from 'components/shared/StatusLabel';
import Table from 'components/shared/Table';
import TableCountLabel from 'components/shared/TableCountLabel';
import { useHistory } from 'react-router';
import expenseReportStatusConfig from 'store/configs/ExpenseReportStatusConfig';
import routes from 'store/configs/Routes';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';
import ExpenseReportTableType from 'store/enums/ExpenseReportTableType';
import { ExpenseReport } from 'store/types/Expense';
import { SortConfig, TableColumn } from 'store/types/Table';
import { getFullDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import { getPrice } from 'util/Payment';

import styles from 'components/expense/Expense.module.scss';

interface ExpenseReportTableProps {
  data: ExpenseReport[];
  tableType: ExpenseReportTableType;
  onModalDeleteOpen?: (expenseId: string) => void;
  onPrint?: (expenseReportId: string) => void;
}

export const getStatusLabel = (status: ExpenseReportStatus): React.ReactNode => {
  const statusConfig = expenseReportStatusConfig[status];
  return (
    <StatusLabel
      theme={statusConfig?.theme || 'grey'}
      status={statusConfig?.name || status}
      variant={statusConfig?.variant || 'filled'}
      className={styles.expenseReportStatus}
    />
  );
};

const ExpenseReportTable: React.FunctionComponent<ExpenseReportTableProps> = ({
  data = [],
  tableType,
  onModalDeleteOpen,
  onPrint,
}) => {
  const history = useHistory();

  const [{ initialList = [], list = [], sort }, dispatch] = useReducer<
    Reducer<ExpenseReportTableState, ExpenseReportTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: ExpenseReportTableActionType.SetInitialList,
        payload: { initialList: data ?? [] },
      });
    }
  }, [data, tableType]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({ type: ExpenseReportTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleReportAction = (reportId: string) => {
    history.push(`${routes.expenseReport}/${reportId}`);
    return;
  };

  const getReportActionButton = ({ id, status }: ExpenseReport) => {
    switch (status) {
      case ExpenseReportStatus.Draft:
        return (
          <div className={styles.buttonGroup}>
            <Button
              color={'primary'}
              variant={'outlined'}
              className={styles.tableButton}
              onClick={() => handleReportAction(id)}
            >
              {'Edit'}
            </Button>
            {onModalDeleteOpen && (
              <Button
                color={'error'}
                variant={'outlined'}
                className={styles.tableButton}
                onClick={() => onModalDeleteOpen(id)}
              >
                {'Remove'}
              </Button>
            )}
          </div>
        );
      case ExpenseReportStatus.PendingApproval:
        return (
          <Button
            color={'primary'}
            variant={'contained'}
            className={styles.tableButton}
            onClick={() => handleReportAction(id)}
          >
            {'View'}
          </Button>
        );
      case ExpenseReportStatus.NeedInfo:
        return (
          <Button
            color={'primary'}
            variant={'outlined'}
            className={styles.tableButton}
            onClick={() => handleReportAction(id)}
          >
            {'Edit'}
          </Button>
        );
      case ExpenseReportStatus.Rejected:
        return (
          <Button
            color={'primary'}
            variant={'contained'}
            className={styles.tableButton}
            onClick={() => handleReportAction(id)}
          >
            {'View'}
          </Button>
        );
      case ExpenseReportStatus.Approved:
        return (
          <Button
            color={'primary'}
            variant={'contained'}
            className={styles.tableButton}
            onClick={() => handleReportAction(id)}
          >
            {'View'}
          </Button>
        );
      case ExpenseReportStatus.PaidInFull:
        return (
          <Button
            color={'primary'}
            variant={'contained'}
            className={styles.tableButton}
            onClick={() => handleReportAction(id)}
          >
            {'View'}
          </Button>
        );
      default:
        return '';
    }
  };

  const draftStatusColumns: Array<TableColumn<ExpenseReport>> = [
    {
      dataIndex: 'transactionNumber',
      label: <span className={styles.nowrap}>Ref #</span>,
      sortable: false,
      verticalAlign: 'top',
    },
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (status: ExpenseReportStatus) => getStatusLabel(status),
    },
    {
      dataIndex: 'memo',
      label: 'Purpose',
      sortable: true,
      verticalAlign: 'top',
      render: (memo: string) => <ShowMoreText maxLength={50}>{memo}</ShowMoreText>,
    },
    {
      dataIndex: 'amount',
      label: 'Amount',
      sortable: true,
      verticalAlign: 'top',
      render: (amount: number) => getPrice(amount),
    },
    {
      key: 'actions',
      label: 'Action',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseReport) => getReportActionButton(record),
    },
  ];

  const submittedStatusColumns: Array<TableColumn<ExpenseReport>> = [
    {
      dataIndex: 'transactionNumber',
      label: <span className={styles.nowrap}>Ref #</span>,
      sortable: false,
      verticalAlign: 'top',
    },
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (status: ExpenseReportStatus) => getStatusLabel(status),
    },
    {
      dataIndex: 'date',
      label: 'Date',
      sortable: true,
      align: 'center',
      verticalAlign: 'top',
      render: (date: Date) => getFullDate(date.toString()),
    },
    {
      dataIndex: 'memo',
      label: 'Purpose',
      sortable: true,
      verticalAlign: 'top',
      render: (memo: string) => <ShowMoreText maxLength={50}>{memo}</ShowMoreText>,
    },
    {
      dataIndex: 'amount',
      label: 'Amount',
      sortable: true,
      verticalAlign: 'top',
      render: (amount: number) => getPrice(amount),
    },
    {
      key: 'print',
      label: 'Print',
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseReport) => {
        return onPrint ? (
          <IconButton color={'primary'} onClick={() => onPrint(record.id)} style={{ padding: '0' }}>
            <Print />
          </IconButton>
        ) : null;
      },
    },
    {
      key: 'actions',
      label: 'Action',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseReport) => getReportActionButton(record),
    },
  ];

  const getColumns = () => {
    switch (tableType) {
      case ExpenseReportTableType.ReportDraft:
        return draftStatusColumns;
      case ExpenseReportTableType.ReportSubmitted:
        return submittedStatusColumns;
      default:
        return draftStatusColumns;
    }
  };

  return (
    <>
      <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
        <Grid {...defaultGridItemProps}>
          <Grid container={true} spacing={2}>
            <Grid {...defaultGridItemProps}>
              <TableCountLabel viewCount={list.length} totalCount={initialList.length} />
            </Grid>
            <Grid {...defaultGridItemProps}>
              <Table
                columns={getColumns()}
                list={list}
                sort={sort}
                showPagination={true}
                onSortChange={handleSortChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpenseReportTable;
