import { Button, Checkbox, Grid, ListItemText, MenuItem, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { SortConfig, TableColumn } from 'store/types/Table';
import Table from 'components/shared/Table';
import Card from 'components/shared/Card';
import CommunityMemberModal from 'components/communities/CommunityMemberModal';
import SearchInput from 'components/shared/SearchInput';
import CommunityMember from 'store/types/CommunityMember';
import { PublicUser } from 'store/types/User';
import { getCountryName, getStringValue, getUserFullName, NUMBER_DATE_FORMAT } from 'util/Format';
import { defaultGridItemProps, defaultSnackbarErrorProps, getButtonLoadingProps } from 'util/Layout';
import TableCountLabel from 'components/shared/TableCountLabel';
import Address from 'store/types/Address';
import UseRequestData from 'store/types/UseRequestData';
import { userFullNameSortLabel } from 'util/Table';
import reducer, {
  CommunityMembersSectionAction,
  CommunityMembersSectionActionType,
  CommunityMembersSectionState,
  initialState,
  TableCommunityUser,
} from './CommunityMembersSectionReducer';
import { ConfigContext } from 'components/ConfigGuard';
import { multipleSelectProps } from 'util/Form';
import SelectOption from 'store/types/SelectOption';

import commonStyles from 'styles/common.module.scss';
import pageStyles from '../CommunityDetailsSection.module.scss';
import styles from './CommunityMembersSection.module.scss';

interface CommunityMembersSectionRequests {
  downloadRosterRequest?: (communityId: string, roles: string[], communityName: string) => Promise<null>;
  getUserRequest?: (communityId: string, userId: string) => Promise<PublicUser>;
}

interface CommunityMembersSectionProps extends UseRequestData<CommunityMember[]> {
  communityId: string;
  communityName: string;
  roles?: SelectOption[];
  description?: string;
  extendedTableView?: boolean;
  downloadRosterEnabled?: boolean;
  requests?: CommunityMembersSectionRequests;
}

export const getAddressString = (address?: Address): string => {
  if (address) {
    const { city, country, stateRegion } = address;
    const countryName = getCountryName(country) || '';

    return city ? `${city}, ${stateRegion || countryName}` : countryName;
  } else {
    return '';
  }
};

const CommunityMembersSection: React.FunctionComponent<CommunityMembersSectionProps> = ({
  communityId,
  communityName,
  downloadRosterEnabled = false,
  extendedTableView = false,
  description = '',
  requests,
  data,
  loading,
  error,
  roles = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAcsTheme } = useContext(ConfigContext);
  const [{ sort, list, initialList, clickedItem, filter }, dispatch] = useReducer<
    Reducer<CommunityMembersSectionState, CommunityMembersSectionAction>
  >(reducer, initialState);
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      dispatch({
        type: CommunityMembersSectionActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: CommunityMembersSectionActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handleProfileButtonClick = useCallback(
    (user: CommunityMember) => () => {
      dispatch({
        type: CommunityMembersSectionActionType.OpenDetails,
        payload: { clickedItem: user },
      });
    },
    []
  );

  const handleDownloadClick = useCallback(() => {
    if (requests?.downloadRosterRequest) {
      setFileLoading(true);
      const selectedRoles: string[] = roles.filter(({ name }) => filter.role.includes(name)).map(({ name }) => name);

      requests
        .downloadRosterRequest(communityId, selectedRoles, communityName)
        .then(() => {
          setFileLoading(false);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setFileLoading(false);
        });
    }
  }, [requests, roles, communityId, communityName, filter.role, enqueueSnackbar]);

  const handleModalClose = useCallback(() => {
    dispatch({
      type: CommunityMembersSectionActionType.CloseDetails,
      payload: {},
    });
  }, []);

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CommunityMembersSectionActionType.UpdateFilter,
      payload: { filter: { search: e.target.value } },
    });
  }, []);

  const handleRoleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CommunityMembersSectionActionType.UpdateFilter,
      payload: { filter: { role: e.target.value } },
    });
  }, []);

  const handleYearFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CommunityMembersSectionActionType.UpdateFilter,
      payload: { filter: { year: e.target.value } },
    });
  }, []);

  /**
   * Gets an array of ten incrementing numbers starting at current year.
   */
  const yearFilterOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 9;

    return Array(endYear - currentYear + 1)
      .fill('')
      .map((_, i) => currentYear + i);
  }, []);

  let columns: Array<TableColumn<TableCommunityUser>> = [
    {
      dataIndex: userFullNameSortLabel,
      label: 'Name',
      sortable: true,
      render: (_: any, record: CommunityMember) => getUserFullName(record, false, false),
    },
    {
      dataIndex: 'role',
      label: 'Role',
      sortable: true,
    },
  ];

  if (extendedTableView) {
    columns = [
      ...columns,
      {
        dataIndex: 'startDate',
        label: 'Member Since',
        sortable: true,
        render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
      },
      {
        dataIndex: 'endDate',
        label: 'Renewal Date',
        sortable: true,
        render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
      },
    ];
  }
  columns.push({
    key: 'profile',
    label: 'Profile',
    align: 'center',
    hidden: !requests?.getUserRequest,
    render: (_: any, record: CommunityMember) => (
      <Button
        size={'small'}
        variant={'outlined'}
        color={'primary'}
        className={pageStyles.sectionTableActionButton}
        onClick={handleProfileButtonClick(record)}
      >
        View
      </Button>
    ),
  });

  return (
    <>
      <Card
        bordered={true}
        title={<h3>Members</h3>}
        headerClassName={pageStyles.sectionHeader}
        headerAction={
          downloadRosterEnabled &&
          requests?.downloadRosterRequest && (
            <Button
              color={'primary'}
              variant={'contained'}
              className={pageStyles.sectionCardActionButton}
              disabled={fileLoading}
              onClick={handleDownloadClick}
              size={'small'}
              {...getButtonLoadingProps(fileLoading)}
            >
              {isAcsTheme ? 'Download Results' : 'Download Roster'}
            </Button>
          )
        }
      >
        {description && <p className={pageStyles.sectionDescription}>{description}</p>}
        {error ? (
          <Alert severity={'error'} className={commonStyles.alert}>
            {error}
          </Alert>
        ) : (
          <>
            <Grid container={true} spacing={2}>
              {!!roles.length && (
                <Grid {...defaultGridItemProps} md={4}>
                  <TextField
                    {...multipleSelectProps(roles, 'Select Roles')}
                    onChange={handleRoleFilterChange}
                    value={filter.role}
                    size={'small'}
                    select={true}
                    disabled={loading}
                    InputProps={{ className: styles.selectRoleField }}
                  >
                    {roles.map(({ id, name }: SelectOption) => (
                      <MenuItem value={name} key={`role-${id}`}>
                        <Checkbox checked={filter.role.includes(name)} color={'primary'} size={'small'} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid {...defaultGridItemProps} md={8}>
                <SearchInput
                  placeholder={'Search Members'}
                  value={filter.search}
                  onChange={handleSearchChange}
                  loading={loading}
                />
              </Grid>
              <Grid {...defaultGridItemProps} md={4}>
                <TextField label={'Filter by Year'} select={true} value={filter.year} onChange={handleYearFilterChange}>
                  <MenuItem value={''}>
                    <em>All</em>
                  </MenuItem>
                  {yearFilterOptions.map((item) => (
                    <MenuItem value={item} key={`year-filter-${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {extendedTableView && (
                <Grid {...defaultGridItemProps}>
                  <TableCountLabel
                    viewCount={list.length}
                    totalCount={initialList.length}
                    loading={loading}
                    className={styles.countLabel}
                  />
                </Grid>
              )}
            </Grid>
            <Table
              columns={columns}
              list={list}
              sort={sort}
              onSortChange={handleSortChange}
              className={pageStyles.sectionTable}
              loading={loading}
              showPagination={true}
            />
          </>
        )}
      </Card>
      {!!clickedItem && requests?.getUserRequest && (
        <CommunityMemberModal
          user={clickedItem}
          open={!!clickedItem}
          onClose={handleModalClose}
          communityId={communityId}
          getUserRequest={requests.getUserRequest}
        />
      )}
    </>
  );
};
export default CommunityMembersSection;
