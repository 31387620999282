import { ExpenseCategory, ExpenseForm, ExpenseReport } from 'store/types/Expense';
import ExpenseReportStatus from 'store/enums/ExpenseReportStatus';

export enum ExpenseReportActionType {
  Initialize = 'Initialize',
  SetLoading = 'SetLoading',
  SetCategory = 'SetCategory',
  SetAmount = 'SetAmount',
  ToggleExpenseModal = 'ToggleExpenseModal',
  ToggleDeleteExpenseConfirmation = 'ToggleDeleteExpenseConfirmation',
  ToggleSubmitReportConfirmationModal = 'ToggleSubmitReportConfirmationModal',
}

export interface ExpenseReportState {
  expenseReport?: ExpenseReport;
  selectedExpenseItem?: ExpenseForm | null;
  selectedCategory?: ExpenseCategory;
  amount: string;
  expenseModalOpen: boolean;
  deleteExpenseConfirmationOpen: boolean;
  submitReportConfirmationOpen: boolean;
  loading: boolean;
  readOnly: boolean;
}

export interface ExpenseReportAction {
  type: ExpenseReportActionType;
  payload: Partial<ExpenseReportState>;
}

export const initialState: ExpenseReportState = {
  expenseModalOpen: false,
  deleteExpenseConfirmationOpen: false,
  submitReportConfirmationOpen: false,
  loading: false,
  readOnly: false,
  amount: '',
};

export const isReadOnly = (status: string) => {
  return status !== '' && status !== ExpenseReportStatus.Draft && status !== ExpenseReportStatus.NeedInfo;
};

const reducer = (state: ExpenseReportState, { type, payload }: ExpenseReportAction): ExpenseReportState => {
  if (type === ExpenseReportActionType.Initialize) {
    const { expenseReport } = payload;

    expenseReport?.expenses.forEach((expense) => {
      expense.categoryName = expense?.category?.name ?? '';
    });

    return { ...state, readOnly: isReadOnly(expenseReport?.status ?? '') };
  }

  if (type === ExpenseReportActionType.SetLoading) {
    const { loading = false } = payload;

    return { ...state, loading };
  }

  if (type === ExpenseReportActionType.SetCategory) {
    const { selectedCategory } = payload;

    return { ...state, selectedCategory };
  }

  if (type === ExpenseReportActionType.SetAmount) {
    const { amount = '' } = payload;

    return { ...state, amount };
  }

  if (type === ExpenseReportActionType.ToggleExpenseModal) {
    const { selectedExpenseItem } = payload;
    const isOpen = !state.expenseModalOpen;
    const selectedItem = isOpen ? selectedExpenseItem : null;

    return { ...state, selectedExpenseItem: selectedItem, expenseModalOpen: isOpen };
  }

  if (type === ExpenseReportActionType.ToggleDeleteExpenseConfirmation) {
    const { selectedExpenseItem } = payload;
    const isOpen = !state.deleteExpenseConfirmationOpen;
    const selectedItem = isOpen ? selectedExpenseItem : null;

    return { ...state, selectedExpenseItem: selectedItem, deleteExpenseConfirmationOpen: isOpen };
  }

  if (type === ExpenseReportActionType.ToggleSubmitReportConfirmationModal) {
    return { ...state, submitReportConfirmationOpen: !state.submitReportConfirmationOpen };
  }

  return state;
};

export default reducer;
