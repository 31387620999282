import React, { useCallback, useContext, useMemo } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import ExpenseReportCollectionSection from 'components/expense/ExpenseReportCollectionPage/ExpenseReportCollectionSection';
import { ParametersContext } from 'components/ParametersGuard';
import { useHistory, useLocation } from 'react-router';
import { expenseReportCollectionRoutes } from 'store/configs/Routes';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

const MY_REPORTS_TAB = '1';
const DELEGATION_REPORTS_TAB = '2';

const ExpenseReportCollectionPage: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      expenseReport: { reportsTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseReport: { reportsPageHeader, reportsTabHeader, reportsDelegationTabHeader },
  } = useContext(ParametersContext);

  const history = useHistory();
  const { pathname } = useLocation();

  const activeTab: string = useMemo(() => getActiveTabValue(pathname, expenseReportCollectionRoutes), [pathname]);

  const handleTabChange = useCallback(
    (_: any, value = '1') => {
      history.replace(getActiveTabRoute(value, expenseReportCollectionRoutes));
    },
    [history]
  );

  return (
    <>
      <div className={styles.contentBlock}>
        {reportsTitle && (
          <div className={styles.contentBlock}>
            <h1 className={commonStyles.pageTitle}>{reportsTitle}</h1>
          </div>
        )}
        {reportsPageHeader && (
          <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsPageHeader }} />
        )}
      </div>

      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor={'primary'}
          textColor={'primary'}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          <Tab
            value={MY_REPORTS_TAB}
            label={'My Reimbursements'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
          <Tab
            value={DELEGATION_REPORTS_TAB}
            label={'Authorized Reimbursements'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        </Tabs>

        <TabPanel value={MY_REPORTS_TAB} className={commonStyles.tabPanel}>
          {reportsTabHeader && (
            <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsTabHeader }} />
          )}

          <ExpenseReportCollectionSection isDelegationTabSelected={false} />
        </TabPanel>

        <TabPanel value={DELEGATION_REPORTS_TAB} className={commonStyles.tabPanel}>
          {reportsDelegationTabHeader && (
            <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: reportsDelegationTabHeader }} />
          )}

          <ExpenseReportCollectionSection isDelegationTabSelected={true} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default ExpenseReportCollectionPage;
