import {
  AutocompleteRenderInputParams,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getRequiredSelectMessage, getValidationProps, isCanadaSelected, isUSSelected } from 'util/Form';
import UserService from 'services/api/UserService';
import { PublicUser } from 'store/types/User';
import {
  defaultGridContainerProps,
  defaultGridItemProps,
  defaultSnackbarErrorProps,
  getInputLoadingProps,
} from 'util/Layout';
import { UserSelectOption } from 'store/types/SelectOption';

import styles from './UserSearchFormItem.module.scss';

const fieldName = 'users';
const MIN_CHARS_COUNT = 3;

const getSearchUserFullName = (firstName: string, lastName: string) => {
  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  } else if (lastName || firstName) {
    return lastName || firstName;
  } else {
    return '';
  }
};

interface UserSearchFormItemProps {
  companyId?: string;
  disabledUserIds?: string[];
}

const UserSearchFormItem: React.FunctionComponent<UserSearchFormItemProps> = ({ companyId, disabledUserIds = [] }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<UserSelectOption[]>([]);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { error, helperText } = getValidationProps(fieldName, errors);
  const selectedUsers: UserSelectOption[] = useWatch({ control, name: fieldName, defaultValue: [] });
  const inputHelperText: React.ReactNode = useMemo(
    () => helperText || (!selectedUsers.length ? 'Please enter at least 3 characters' : ''),
    [helperText, selectedUsers]
  );
  const searchButtonDisabled = useMemo(
    () => selectedUsers.length > 0 || search.length < MIN_CHARS_COUNT || loading,
    [selectedUsers, search, loading]
  );

  const handleInputChange = useCallback(
    (_: any, value: string) => {
      if (!selectedUsers.length) {
        setSearch(value);
      }
    },
    [selectedUsers]
  );

  const handleSearchClick = useCallback(() => {
    setLoading(true);
    UserService.searchUser(search, companyId)
      .then((users: PublicUser[] = []) => {
        setLoading(false);
        setOptions(
          users.map((user) => ({
            id: user.id,
            name: getSearchUserFullName(user.firstName, user.lastName),
            email: user.email,
            city: user.address?.city,
            stateRegion: user.address?.stateRegion,
            country: user.address?.country,
            nccerCardNumber: user.nccerCardNumber,
          }))
        );
        setSearch('');
        setOpen(true);
      })
      .catch((errorMessage: string) => {
        setLoading(false);
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [search, companyId, enqueueSnackbar]);

  const getOptionSelected = useCallback(
    (option: UserSelectOption, value: UserSelectOption) => option.id === value.id,
    []
  );

  const getOptionLabel = useCallback(
    ({ name, email = '', nccerCardNumber, stateRegion, country, city }: UserSelectOption) => {
      let result = '';

      if (name) {
        result = `${name}`;
      }
      if (country) {
        result = `${result} ${
          isUSSelected(country) || isCanadaSelected(country) ? `(${city}, ${stateRegion})` : `(${city}, ${country})`
        }`;
      }
      if (email) {
        result = result ? `${result} - ${email}` : `${email}`;
      }
      if (nccerCardNumber) {
        result = result ? `${result} - ${nccerCardNumber}` : `${nccerCardNumber}`;
      }
      return result;
    },
    []
  );

  const validateField = useCallback(
    (value: UserSelectOption[] = []) => !!value.length || getRequiredSelectMessage('user'),
    []
  );

  const searchInputRender = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      required={true}
      error={error}
      placeholder={!selectedUsers.length ? 'Email, First or Last Name' : ''}
      InputProps={{
        ...params.InputProps,
        ...(loading
          ? getInputLoadingProps(loading)
          : {
              startAdornment: (
                <>
                  <InputAdornment position={'start'}>
                    <Search color={'inherit'} />
                  </InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ),
            }),
      }}
    />
  );

  return (
    <Grid {...defaultGridContainerProps} spacing={0}>
      <Grid {...defaultGridItemProps} xs={true}>
        <FormControl fullWidth={true} error={error} required={true}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                loading={loading}
                disableClearable={true}
                clearOnBlur={false}
                forcePopupIcon={true}
                options={options.sort((a, b) => (a.name < b.name ? -1 : 1))}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={getOptionSelected}
                getOptionDisabled={(option) => disabledUserIds.includes(option.id)}
                filterOptions={(x) => x}
                renderTags={(value = []) => (
                  <span className={styles.tag}>{value.map((option) => getOptionLabel(option))}</span>
                )}
                inputValue={search}
                renderInput={searchInputRender}
                onInputChange={handleInputChange}
                value={value}
                onChange={(_: any, value: (UserSelectOption | string)[]) => {
                  onChange(value.length ? [value.pop()] : []);
                }}
                multiple={true}
              />
            )}
            name={fieldName}
            control={control}
            rules={{ validate: validateField }}
          />
          {inputHelperText && <FormHelperText>{inputHelperText}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid {...defaultGridItemProps} xs={'auto'} className={styles.searchButtonWrapper}>
        <Button
          variant={'outlined'}
          color={'primary'}
          className={styles.searchButton}
          onClick={handleSearchClick}
          disabled={searchButtonDisabled}
        >
          {'Search'}
        </Button>
      </Grid>
    </Grid>
  );
};
export default UserSearchFormItem;
