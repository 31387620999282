import React, { Reducer, useCallback, useEffect, useReducer } from 'react';
import { Button, Grid } from '@mui/material';
import reducer, {
  ExpenseDelegationTableAction,
  ExpenseDelegationTableActionType,
  ExpenseDelegationTableState,
  initialState,
} from 'components/expense/ExpenseProfilePage/ExpenseDelegationSection/ExpenseDelegationTable/ExpenseDelegationTableReducer';
import FilterToggleGroup from 'components/shared/FilterToggleGroup';
import Spinner from 'components/shared/Spinner';
import StatusLabel from 'components/shared/StatusLabel';
import Table from 'components/shared/Table';
import TableCountLabel from 'components/shared/TableCountLabel';
import { useSnackbar } from 'notistack';
import ExpenseService from 'services/api/ExpenseService';
import expenseDelegationStatusConfig from 'store/configs/ExpenseDelegationStatusConfig';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';
import RequestStatus from 'store/enums/CompanyAccessRequestStatus';
import { SortConfig, TableColumn } from 'store/types/Table';
import { UserEmployerAccessRequest } from 'store/types/UserEmployerAccessRequest';
import { getFullDate } from 'util/Format';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import styles from 'components/expense/Expense.module.scss';

interface ExpenseDelegationTableProps {
  data: UserEmployerAccessRequest[];
  isSentRequest: boolean;
  onChange: () => void;
}

export const getStatusLabel = (status: RequestStatus): React.ReactNode => {
  const statusConfig = expenseDelegationStatusConfig[status];
  return (
    <StatusLabel
      theme={statusConfig?.theme || 'grey'}
      status={statusConfig?.name || status}
      variant={statusConfig?.variant || 'filled'}
      className={styles.delegationStatus}
    />
  );
};

const ExpenseDelegationTable: React.FunctionComponent<ExpenseDelegationTableProps> = ({
  data = [],
  isSentRequest,
  onChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [{ initialList = [], list = [], loading, sort, statuses }, dispatch] = useReducer<
    Reducer<ExpenseDelegationTableState, ExpenseDelegationTableAction>
  >(reducer, initialState);

  useEffect(() => {
    if (data) {
      dispatch({
        type: ExpenseDelegationTableActionType.SetInitialList,
        payload: { initialList: data },
      });
    }
  }, [data]);

  const handleSort = useCallback((newSort: SortConfig) => {
    dispatch({ type: ExpenseDelegationTableActionType.UpdateSort, payload: { sort: newSort } });
  }, []);

  const handleFilter = useCallback((selectedStatusFilters: string[]) => {
    dispatch({
      type: ExpenseDelegationTableActionType.UpdateFilter,
      payload: { selectedStatusFilters: selectedStatusFilters },
    });
  }, []);

  const handleCancel = (requestId: string) => {
    dispatch({
      type: ExpenseDelegationTableActionType.SetLoading,
      payload: { loading: true },
    });

    ExpenseService.cancelDelegationRequest(requestId)
      .then(() => {
        enqueueSnackbar('Delegation request successfully canceled', { variant: 'success' });
        onChange();
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      })
      .finally(() => {
        dispatch({
          type: ExpenseDelegationTableActionType.SetLoading,
          payload: { loading: false },
        });
      });
  };

  const handleApprove = (requestId: string) => {
    dispatch({
      type: ExpenseDelegationTableActionType.SetLoading,
      payload: { loading: true },
    });

    ExpenseService.approveDelegationRequest(requestId)
      .then(() => {
        enqueueSnackbar('Delegation request successfully approved', { variant: 'success' });
        onChange();
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      })
      .finally(() => {
        dispatch({
          type: ExpenseDelegationTableActionType.SetLoading,
          payload: { loading: false },
        });
      });
  };

  const handleReject = (requestId: string) => {
    dispatch({
      type: ExpenseDelegationTableActionType.SetLoading,
      payload: { loading: true },
    });

    ExpenseService.rejectDelegationRequest(requestId)
      .then(() => {
        enqueueSnackbar('Delegation request successfully rejected', { variant: 'success' });
        onChange();
      })
      .catch((error: string) => {
        enqueueSnackbar(error, defaultSnackbarErrorProps);
      })
      .finally(() => {
        dispatch({
          type: ExpenseDelegationTableActionType.SetLoading,
          payload: { loading: false },
        });
      });
  };

  const getDelegationActionButton = ({ id, status }: UserEmployerAccessRequest) => {
    if (isSentRequest) {
      if (status === RequestStatus.Pending) {
        return (
          <div className={styles.buttonGroup}>
            <Button
              color={'error'}
              variant={'outlined'}
              className={styles.tableButton}
              onClick={() => handleCancel(id)}
            >
              {'Cancel'}
            </Button>
          </div>
        );
      } else {
        return null;
      }
    }

    switch (status) {
      case RequestStatus.Approved:
        return (
          <div className={styles.buttonGroup}>
            <Button
              color={'error'}
              variant={'outlined'}
              className={styles.tableButton}
              onClick={() => handleCancel(id)}
            >
              {'Remove'}
            </Button>
          </div>
        );
      case RequestStatus.Canceled:
      case RequestStatus.Rejected:
        return null;
      default:
        return (
          <div className={styles.buttonGroup}>
            <Button
              color={'primary'}
              variant={'outlined'}
              className={styles.tableButton}
              onClick={() => handleApprove(id)}
            >
              {'Approve'}
            </Button>

            <Button
              color={'error'}
              variant={'outlined'}
              className={styles.tableButton}
              onClick={() => handleReject(id)}
            >
              {'Reject'}
            </Button>
          </div>
        );
    }
  };

  const columns: Array<TableColumn<UserEmployerAccessRequest>> = [
    {
      dataIndex: 'id',
      label: 'ID',
      hidden: true,
    },
    {
      dataIndex: 'status',
      label: 'Status',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (status: CompanyAccessRequestStatus) => getStatusLabel(status),
    },
    {
      dataIndex: 'name',
      label: 'Name',
      sortable: true,
      verticalAlign: 'top',
    },
    {
      dataIndex: isSentRequest ? 'approverEmail' : 'requesterEmail',
      label: 'Email',
      sortable: true,
      verticalAlign: 'top',
    },
    {
      dataIndex: 'requestedDate',
      label: 'Requested Date',
      align: 'center',
      sortable: true,
      verticalAlign: 'top',
      render: (date: string) => getFullDate(date),
    },
    {
      key: 'actions',
      label: 'Action',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: UserEmployerAccessRequest) => getDelegationActionButton(record),
    },
  ];

  return (
    <Spinner loading={loading} transparent={true}>
      {isSentRequest && statuses?.length > 1 && (
        <div className={styles.contentBlock}>
          <FilterToggleGroup items={statuses} onChange={handleFilter} />
        </div>
      )}

      <div className={styles.contentBorder}>
        <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
          <Grid {...defaultGridItemProps}>
            <Grid container={true} spacing={2}>
              <Grid {...defaultGridItemProps}>
                <TableCountLabel viewCount={list.length} totalCount={initialList.length} />
              </Grid>
              <Grid {...defaultGridItemProps}>
                <Table columns={columns} list={list} sort={sort} showPagination={true} onSortChange={handleSort} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Spinner>
  );
};

export default ExpenseDelegationTable;
