import React from 'react';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Button, Grid, IconButton } from '@mui/material';
import Table from 'components/shared/Table';
import { ExpenseFile } from 'store/types/Expense';
import { TableColumn } from 'store/types/Table';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';

import styles from 'components/expense/Expense.module.scss';

interface TaxFilesTableProps {
  data: ExpenseFile[];
  onDownload: (fileId: string, filename: string) => void;
  onDelete: (fileId: string) => void;
}

const TaxFileTable: React.FunctionComponent<TaxFilesTableProps> = ({ data = [], onDownload, onDelete }) => {
  const columns: Array<TableColumn<ExpenseFile>> = [
    {
      dataIndex: 'name',
      label: 'Document Name',
      sortable: false,
      verticalAlign: 'top',
    },
    {
      key: 'attachment',
      label: 'Attachment',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseFile) => {
        return (
          <IconButton color={'primary'} onClick={() => onDownload(record.id, record.name)} style={{ padding: '0' }}>
            <ReceiptLongOutlinedIcon />
          </IconButton>
        );
      },
    },
    {
      key: 'actions',
      label: 'Action',
      sortable: false,
      align: 'center',
      verticalAlign: 'top',
      render: (_: any, record: ExpenseFile) => {
        return (
          <Button
            color={'error'}
            variant={'outlined'}
            className={styles.tableButton}
            onClick={() => onDelete(record.id)}
          >
            {'Remove'}
          </Button>
        );
      },
    },
  ];

  return (
    <Grid {...defaultGridContainerProps} justifyContent={'flex-end'}>
      <Grid {...defaultGridItemProps}>
        <Grid container={true}>
          <Grid {...defaultGridItemProps}>
            <Table columns={columns} list={data} showPagination={false} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaxFileTable;
