import React from 'react';
import Group from '@mui/icons-material/Group';
import Avatar from 'components/shared/Avatar';
import { getFormattedDate } from 'util/Format';
import { Divider } from '@mui/material';
import { ChapterCustomer } from 'store/types/Chapter';

import styles from './ChapterListItem.module.scss';

interface ChapterListItemProps {
  item: ChapterCustomer;
  active?: boolean;
}

const ChapterListItem: React.FunctionComponent<ChapterListItemProps> = ({ item, active = false }) => {
  const { startDate, endDate } = item;
  const { name, iconUrl } = item.chapter;
  const start: string = startDate?.toString() ?? '';
  const end: string = endDate?.toString() ?? '';

  return (
    <div className={styles.container}>
      <Avatar src={iconUrl} icon={<Group />} className={styles.avatar} />
      <div className={styles.information}>
        <h5 className={styles.name}>{name}</h5>
        <h3 className={styles.subTitle}>{active ? 'Active Member' : 'Member History'}</h3>
        <Divider className={styles.divider} />
        <div className={styles.memberHistory}>
          <div className={styles.member}>
            <Divider className={styles.divider} />
            {start && <span>{`Start Date: ${getFormattedDate(start)}`}</span>}
            {end && <span>{`End Date: ${getFormattedDate(end)}`}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChapterListItem;
