import React, { Reducer, useCallback, useReducer } from 'react';
import AttachFile from '@mui/icons-material/AttachFile';
import { Link } from '@mui/material';
import reducer, {
  ExpenseReportAction,
  ExpenseReportActionType,
  ExpenseReportState,
  initialState,
} from 'components/expense/ExpenseReportPage/ExpenseReportReducer';
import Spinner from 'components/shared/Spinner';
import { useSnackbar } from 'notistack';
import ExpenseService from 'services/api/ExpenseService';
import { defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface FileListDownloadProps {
  expenseReportId: string;
  expenseId: string;
  filename: string;
}

const FileListDownload: React.FunctionComponent<FileListDownloadProps> = ({ expenseReportId, expenseId, filename }) => {
  const [{ loading }, dispatch] = useReducer<Reducer<ExpenseReportState, ExpenseReportAction>>(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();

  const handleDownloadFile = useCallback(() => {
    dispatch({
      type: ExpenseReportActionType.SetLoading,
      payload: { loading: true },
    });
    ExpenseService.getReceiptFile(expenseReportId, expenseId, filename)
      .then(() => {
        dispatch({
          type: ExpenseReportActionType.SetLoading,
          payload: { loading: false },
        });
      })
      .catch((errorMessage: string) => {
        dispatch({
          type: ExpenseReportActionType.SetLoading,
          payload: { loading: false },
        });
        enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
      });
  }, [enqueueSnackbar, expenseId, expenseReportId, filename]);

  return (
    <Spinner loading={loading}>
      <ul>
        <AttachFile className={styles.fileIcon} />
        <Link className={commonStyles.link} onClick={handleDownloadFile}>
          {filename}
        </Link>
      </ul>
    </Spinner>
  );
};

export default FileListDownload;
