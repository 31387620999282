import { SortConfig } from 'store/types/Table';
import { sorter } from 'util/Table';
import { UserEmployerAccessRequest } from 'store/types/UserEmployerAccessRequest';

export enum ExpenseDelegationTableActionType {
  SetInitialList = 'SetInitialList',
  SetLoading = 'SetLoading',
  SetSelectedRequestId = 'SetSelectedRequestId',
  UpdateSort = 'UpdateSort',
  UpdateFilter = 'UpdateFilter',
}

export interface ExpenseDelegationTableState {
  selectedRequestId: string;
  initialList: UserEmployerAccessRequest[];
  list: UserEmployerAccessRequest[];
  sort: SortConfig<UserEmployerAccessRequest>;
  statuses: string[];
  selectedStatusFilters: string[];
  loading: boolean;
}

export interface ExpenseDelegationTableAction {
  type: ExpenseDelegationTableActionType;
  payload: Partial<ExpenseDelegationTableState>;
}

const getSortedList = (
  list: UserEmployerAccessRequest[] = [],
  sort: SortConfig<UserEmployerAccessRequest>
): UserEmployerAccessRequest[] => (list.length ? [...list].sort(sorter<UserEmployerAccessRequest>(sort)) : [...list]);

export const initialState: ExpenseDelegationTableState = {
  selectedRequestId: '',
  initialList: [],
  list: [],
  sort: { column: 'name', direction: 'desc' },
  statuses: [],
  selectedStatusFilters: [],
  loading: false,
};

const getFilteredList = (list: UserEmployerAccessRequest[] = [], filterStatuses: string[]) => {
  return filterStatuses.length > 0 ? list.filter((item) => filterStatuses.includes(item.status)) : list;
};

const getFilterStatuses = (requests: UserEmployerAccessRequest[]) => {
  return requests
    .reduce((acc: string[], currentValue: UserEmployerAccessRequest) => {
      if (!acc.find((item) => item === currentValue.status)) {
        acc.push(currentValue.status);
      }
      return acc;
    }, [])
    .sort();
};

const reducer = (
  state: ExpenseDelegationTableState,
  { type, payload }: ExpenseDelegationTableAction
): ExpenseDelegationTableState => {
  if (type === ExpenseDelegationTableActionType.SetInitialList) {
    const { initialList = [] } = payload;

    return {
      ...state,
      initialList,
      list: initialList,
      statuses: getFilterStatuses(initialList),
    };
  }

  if (type === ExpenseDelegationTableActionType.UpdateSort) {
    const { sort = {} } = payload;

    return {
      ...state,
      sort,
      list: getSortedList(state.initialList, sort),
    };
  }

  if (type === ExpenseDelegationTableActionType.UpdateFilter) {
    const { selectedStatusFilters = [] } = payload;
    const filteredList = getFilteredList(state.initialList, selectedStatusFilters);

    return {
      ...state,
      selectedStatusFilters,
      list: filteredList,
    };
  }

  if (type === ExpenseDelegationTableActionType.SetSelectedRequestId) {
    const { selectedRequestId = '' } = payload;

    return { ...state, selectedRequestId };
  }

  if (type === ExpenseDelegationTableActionType.SetLoading) {
    const { loading = false } = payload;

    return { ...state, loading };
  }

  return state;
};

export default reducer;
