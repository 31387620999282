import Pagination from '@mui/material/Pagination';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ChapterDetailsCard from 'components/communities/ChapterDetailsCard';
import { useHistory, useLocation } from 'react-router';
import { Chapter, ChapterCustomer } from 'store/types/Chapter';
import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import People from '@mui/icons-material/People';
import classNames from 'classnames';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import Spinner from 'components/shared/Spinner';
import Card from 'components/shared/Card';
import { preselectedCommunityStatePropName } from 'util/Route';
import { getListByPage } from 'util/Table';
import Avatar from 'components/shared/Avatar';
import { ParametersContext } from 'components/ParametersGuard';
import ChapterListItem from 'components/communities/ChapterListItem';

import commonStyles from 'styles/common.module.scss';
import styles from './ChaptersPageView.module.scss';

interface ChaptersPageViewProps {
  data: ChapterCustomer[];
  loading?: boolean;
}

const groupChapters = (list: ChapterCustomer[]): [ChapterCustomer[], ChapterCustomer[]] => {
  const activeChapterCustomers: ChapterCustomer[] = [];
  const inactiveChapterCustomers: ChapterCustomer[] = [];
  const now = new Date();

  list.forEach((chapterCustomer) => {
    const { startDate, endDate } = chapterCustomer;
    const startDateDate = startDate && new Date(startDate as Date);
    const endDateDate = endDate && new Date(endDate as Date);

    if ((!startDateDate || startDateDate <= now) && (!endDateDate || endDateDate > now)) {
      activeChapterCustomers.push(chapterCustomer);
    } else {
      inactiveChapterCustomers.push(chapterCustomer);
    }
  });

  return [activeChapterCustomers, inactiveChapterCustomers];
};

const emptySection = (
  <div className={styles.noData}>
    <span>No chapters to display</span>
  </div>
);
const itemsPerPage = 6;

const ChaptersPageView: React.FunctionComponent<ChaptersPageViewProps> = ({ data, loading = false }) => {
  const history = useHistory();
  const { state, pathname } = useLocation<{ [preselectedCommunityStatePropName]?: string }>();
  const [activeTab, setActiveTab] = useState<string>('1');
  const [selectedChapterCustomerId, setSelectedChapterCustomerId] = useState<string>('');
  const {
    chapters: { currentChaptersTabHeader, pastChaptersTabHeader },
  } = useContext(ParametersContext);

  const [activeChapterCustomers, inactiveChapterCustomers] = useMemo(() => groupChapters(data), [data]);
  const [page, setPage] = useState<number>(1);
  const pagesCount = useMemo(
    () => Math.ceil(activeChapterCustomers.length / itemsPerPage),
    [activeChapterCustomers.length]
  );
  const pageList = useMemo(
    () => getListByPage(activeChapterCustomers, itemsPerPage, page),
    [activeChapterCustomers, page]
  );

  const selectedChapter: Chapter | undefined = useMemo(
    () => data.find(({ id }) => id === selectedChapterCustomerId)?.chapter,
    [selectedChapterCustomerId, data]
  );

  const selectedChapterCustomer: ChapterCustomer = useMemo(
    () => data.find(({ id }) => id === selectedChapterCustomerId) ?? ({} as ChapterCustomer),
    [data, selectedChapterCustomerId]
  );

  useEffect(() => {
    if (state && state[preselectedCommunityStatePropName]) {
      setSelectedChapterCustomerId(state[preselectedCommunityStatePropName] || '');
      history.replace({ pathname, state: null });
    } else if (!selectedChapterCustomerId && activeChapterCustomers.length) {
      setSelectedChapterCustomerId(activeChapterCustomers[0].id);
    }
  }, [selectedChapterCustomerId, state, pathname, history, activeChapterCustomers]);

  const handleTabChange = useCallback((_: any, value = '1') => {
    setActiveTab(value);
  }, []);

  const handleChapterSelected = useCallback(
    (id: string) => () => {
      setSelectedChapterCustomerId(id);
    },
    []
  );

  const handlePageChange = useCallback((_: any, newPage: number) => {
    setPage(newPage);
  }, []);

  return (
    <Grid {...defaultGridContainerProps}>
      <Grid {...defaultGridItemProps}>
        <Card contentClassName={styles.content}>
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor={'primary'}
              textColor={'primary'}
              variant={'scrollable'}
            >
              <Tab value={'1'} label={'Current Chapters'} className={commonStyles.tab} />
              <Tab value={'2'} label={'Past Chapters'} className={commonStyles.tab} />
            </Tabs>
            <TabPanel value={'1'} className={classNames(commonStyles.tabPanel, styles.container)}>
              <Spinner loading={loading} transparent={false}>
                {currentChaptersTabHeader && <p dangerouslySetInnerHTML={{ __html: currentChaptersTabHeader }} />}
                <Grid {...defaultGridContainerProps}>
                  {activeChapterCustomers.length ? (
                    <>
                      {pageList.map((chapterCustomer) => {
                        const { id: chapterId, iconUrl, name: chapterName } = chapterCustomer?.chapter;
                        const { id: chapterCustomerId } = chapterCustomer;
                        const { name: roleName } = chapterCustomer?.role;
                        const chapterSelected = chapterId === selectedChapterCustomerId;
                        return (
                          <Grid {...defaultGridItemProps} key={chapterCustomerId} sm={6} md={4}>
                            <Button
                              color={chapterSelected ? 'inherit' : 'primary'}
                              variant={chapterSelected ? 'contained' : 'outlined'}
                              fullWidth={true}
                              onClick={handleChapterSelected(chapterCustomerId)}
                              className={styles.button}
                              startIcon={
                                <Avatar
                                  src={iconUrl}
                                  icon={<People className={styles.avatarIcon} />}
                                  className={styles.avatar}
                                />
                              }
                            >
                              <Typography className={styles.chapterTitle}>{chapterName}</Typography>
                              {roleName && <Typography>&nbsp;({roleName})</Typography>}
                            </Button>
                          </Grid>
                        );
                      })}
                      <Grid {...defaultGridItemProps} className={styles.paginationContainer}>
                        <Pagination
                          showFirstButton={true}
                          showLastButton={true}
                          count={pagesCount}
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Grid>
                    </>
                  ) : (
                    emptySection
                  )}
                </Grid>
              </Spinner>
            </TabPanel>
            <TabPanel value={'2'} className={classNames(commonStyles.tabPanel, styles.container)}>
              <Spinner loading={loading} transparent={false}>
                {pastChaptersTabHeader && <p dangerouslySetInnerHTML={{ __html: pastChaptersTabHeader }} />}
                <Grid {...defaultGridContainerProps}>
                  {inactiveChapterCustomers.length
                    ? inactiveChapterCustomers.map((item: ChapterCustomer) => (
                        <Grid {...defaultGridItemProps} key={item.id} md={6}>
                          <ChapterListItem item={item} />
                        </Grid>
                      ))
                    : emptySection}
                </Grid>
              </Spinner>
            </TabPanel>
          </TabContext>
        </Card>
      </Grid>
      {selectedChapter && (
        <Grid {...defaultGridItemProps} className={classNames({ [commonStyles.hidden]: activeTab !== '1' })}>
          <ChapterDetailsCard chapterCustomer={selectedChapterCustomer} />
        </Grid>
      )}
    </Grid>
  );
};
export default ChaptersPageView;
