import { ConfigSelectOption } from 'store/types/SelectOption';
import CompanyAccessRequestStatus from 'store/enums/CompanyAccessRequestStatus';

const expenseDelegationStatusConfig: Record<CompanyAccessRequestStatus, ConfigSelectOption> = {
  [CompanyAccessRequestStatus.Approved]: {
    name: CompanyAccessRequestStatus.Approved,
    theme: 'green',
  },
  [CompanyAccessRequestStatus.Pending]: {
    name: CompanyAccessRequestStatus.Pending,
    theme: 'yellow',
  },
  [CompanyAccessRequestStatus.Canceled]: {
    name: CompanyAccessRequestStatus.Canceled,
    theme: 'grey',
  },
  [CompanyAccessRequestStatus.Rejected]: {
    name: CompanyAccessRequestStatus.Rejected,
    theme: 'red',
  },
  [CompanyAccessRequestStatus.Active]: {
    name: CompanyAccessRequestStatus.Active,
    theme: 'grey',
  },
};

export default expenseDelegationStatusConfig;
