import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Alert } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Tabs } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import ExpenseDelegationSection from 'components/expense/ExpenseProfilePage/ExpenseDelegationSection';
import ExpensePaymentOptionsSection from 'components/expense/ExpenseProfilePage/ExpensePaymentOptionsSection';
import TaxFilesSection from 'components/expense/ExpenseProfilePage/TaxFilesSection';
import { ParametersContext } from 'components/ParametersGuard';
import Spinner from 'components/shared/Spinner';
import { useHistory, useLocation } from 'react-router';
import { expenseProfileRoutes } from 'store/configs/Routes';
import { getActiveTabRoute, getActiveTabValue } from 'util/Route';
import ExpenseService from 'services/api/ExpenseService';
import useRequest from 'hooks/useRequest';
import { ExpenseProfile } from 'store/types/Expense';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

const REIMBURSEMENT_OPTIONS_TAB = '1';
const DELEGATION_REQUESTS_TAB = '2';

const ExpenseProfilePage: React.FunctionComponent = () => {
  const {
    modulesSettings: {
      expenseProfile: { profileTitle },
    },
  } = useContext(ConfigContext);

  const {
    expenseProfile: { profilePageHeader },
  } = useContext(ParametersContext);

  const [spinnerLabel, setSpinnerLabel] = useState('');
  const history = useHistory();
  const { pathname } = useLocation();

  const activeTab: string = useMemo(() => getActiveTabValue(pathname, expenseProfileRoutes), [pathname]);

  const handleTabChange = useCallback(
    (_: any, value = REIMBURSEMENT_OPTIONS_TAB) => {
      history.replace(getActiveTabRoute(value, expenseProfileRoutes));
    },
    [history]
  );

  const profileRequest = useCallback(async () => {
    const responseData = await ExpenseService.getExpenseProfile();
    if (responseData) {
      return responseData;
    }
    setSpinnerLabel('Building your Expense Reimbursement Profile...');
    await ExpenseService.createVendor();

    // Add a delay so that NetSuite can finish processing the new vendor record
    await new Promise((resolve) => setTimeout(resolve, 5000));

    setSpinnerLabel('');

    return await ExpenseService.getExpenseProfile();
  }, []);

  const { data, error, loading, refetch } = useRequest<ExpenseProfile>(profileRequest);

  return (
    <>
      {profileTitle && <h1 className={commonStyles.pageTitle}>{profileTitle}</h1>}

      {profilePageHeader && (
        <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: profilePageHeader }} />
      )}

      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor={'primary'}
          textColor={'primary'}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
        >
          <Tab
            value={REIMBURSEMENT_OPTIONS_TAB}
            label={'Reimbursement Options'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
          <Tab
            value={DELEGATION_REQUESTS_TAB}
            label={'Expense Report Delegation'}
            className={commonStyles.tab}
            classes={{ selected: commonStyles.activeTab }}
          />
        </Tabs>
        <TabPanel value={REIMBURSEMENT_OPTIONS_TAB} className={commonStyles.tabPanel}>
          <Spinner loading={loading} transparent={false} label={spinnerLabel}>
            {error ? (
              <Alert severity={'error'} className={commonStyles.alert}>
                {error}
              </Alert>
            ) : (
              data && <ExpensePaymentOptionsSection data={data} />
            )}
          </Spinner>
        </TabPanel>
        <TabPanel value={DELEGATION_REQUESTS_TAB} className={commonStyles.tabPanel}>
          <Spinner loading={loading} transparent={false}>
            <ExpenseDelegationSection profileId={data?.id ?? ''} />
          </Spinner>
        </TabPanel>
      </TabContext>

      {data && activeTab === REIMBURSEMENT_OPTIONS_TAB && (
        <div className={styles.contentBorder}>{data && <TaxFilesSection data={data.taxFiles} refetch={refetch} />}</div>
      )}
    </>
  );
};

export default ExpenseProfilePage;
