import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import classNames from 'classnames';
import stylesDropzone from 'components/applications/ApplicationQuestionsFormSection/FileQuestionFormItem/FileQuestionFormItem.module.scss';
import { ParametersContext } from 'components/ParametersGuard';
import Modal from 'components/shared/Modal';
import { DropzoneArea } from 'mui-file-dropzone';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ExpenseService from 'services/api/ExpenseService';
import { ModalProps } from 'store/types/ComponentProps';
import { TaxFileForm } from 'store/types/Expense';
import { defaultFormProps } from 'util/Form';
import { defaultGridContainerProps, defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface ExpenseFormModalProps extends ModalProps {
  refetch: () => void;
}

const defaultValues: TaxFileForm = {
  files: [],
};

const TaxUploadModal: React.FunctionComponent<ExpenseFormModalProps> = ({ open, onClose, refetch }) => {
  const {
    expenseProfile: { profileTaxDocumentUploadHeader },
  } = useContext(ParametersContext);

  const MAX_FILE_LIMIT = 1;
  const MIN_FILE_SIZE = 0;
  const MAX_FILE_SIZE = 2000000;

  const form = useForm<TaxFileForm>({ ...defaultFormProps, defaultValues });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = form;

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [showMinFileSizeError, setShowMinFileSizeError] = useState(false);

  const handleClose = useCallback(() => {
    reset();
    onClose();
    setShowMinFileSizeError(false);
  }, [onClose, reset]);

  const handleFileChange = useCallback(
    (onChange) => (loadedFiles: File[]) => {
      if (loadedFiles.length > 0) {
        setShowMinFileSizeError(loadedFiles[0].size === MIN_FILE_SIZE);
      } else {
        setShowMinFileSizeError(false);
      }
      onChange(loadedFiles);
    },
    []
  );

  const handleUpload = useCallback(
    (data: TaxFileForm) => {
      if (data?.files?.length !== 1) {
        return;
      }

      setLoading(true);

      ExpenseService.uploadTaxFile(data.files[0])
        .then(() => {
          setLoading(false);
          refetch();
          handleClose();
          enqueueSnackbar('Tax document successfully uploaded', { variant: 'success' });
        })
        .catch((errorMessage: string) => {
          setLoading(false);
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        });
    },
    [enqueueSnackbar, handleClose, refetch]
  );

  return (
    <Modal
      title={'Upload Document'}
      open={open}
      onClose={handleClose}
      disableBackdropClick={false}
      maxWidth={'sm'}
      loading={loading}
      actions={
        <Button
          color={'secondary'}
          variant={'contained'}
          className={styles.createNewExpenseButton}
          disabled={!isDirty || !isValid}
          onClick={handleSubmit(handleUpload)}
        >
          {'Add'}
        </Button>
      }
    >
      <FormProvider {...form}>
        <Grid {...defaultGridContainerProps}>
          {profileTaxDocumentUploadHeader && (
            <Grid {...defaultGridItemProps}>
              <div dangerouslySetInnerHTML={{ __html: profileTaxDocumentUploadHeader }} />
            </Grid>
          )}

          <Grid {...defaultGridItemProps} sm={'auto'}>
            <h2>{'Attach Document'}</h2>

            <Controller
              name={'files'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <DropzoneArea
                  onChange={handleFileChange(onChange)}
                  onDelete={onChange}
                  dropzoneText={'Upload Files'}
                  maxFileSize={MAX_FILE_SIZE}
                  filesLimit={MAX_FILE_LIMIT}
                  fileObjects={value}
                  showAlerts={['error']}
                  clearOnUnmount={true}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview={true}
                  previewText={''}
                  classes={{
                    root: classNames(stylesDropzone.dropzone),
                    textContainer: stylesDropzone.dropzoneTextContainer,
                    text: stylesDropzone.dropzoneText,
                    icon: stylesDropzone.dropzoneIcon,
                  }}
                  previewGridClasses={{
                    container: stylesDropzone.previewContainer,
                    item: stylesDropzone.previewImageContainer,
                  }}
                  dropzoneProps={{ noDrag: true }}
                  previewChipProps={{ className: stylesDropzone.chip }}
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  }}
                />
              )}
            />

            {showMinFileSizeError && (
              <Alert severity={'error'} className={commonStyles.alert}>
                File size must be greater than zero bytes.
              </Alert>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};

export default TaxUploadModal;
