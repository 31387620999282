import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { ParametersContext } from 'components/ParametersGuard';
import UserSearchFormItem from 'components/profile/UserSearchFormItem';
import Modal from 'components/shared/Modal';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import ExpenseService from 'services/api/ExpenseService';
import { ModalProps } from 'store/types/ComponentProps';
import { UserSelectOption } from 'store/types/SelectOption';
import { defaultFormProps } from 'util/Form';
import { defaultGridItemProps, defaultSnackbarErrorProps } from 'util/Layout';

import commonStyles from 'styles/common.module.scss';
import styles from 'components/expense/Expense.module.scss';

interface DelegationRequestModalProps extends ModalProps {
  disabledUserIds: string[];
}

interface ExpenseDelegationFormValues {
  users: UserSelectOption[];
}

const defaultValues: ExpenseDelegationFormValues = {
  users: [],
};

const DelegationRequestModal: React.FunctionComponent<DelegationRequestModalProps> = ({
  open,
  onClose,
  disabledUserIds = [],
}) => {
  const {
    expenseProfile: { requestDelegateAccessHeader },
  } = useContext(ParametersContext);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const form = useForm<ExpenseDelegationFormValues>({ ...defaultFormProps, defaultValues });
  const {
    formState: { isValid },
    handleSubmit,
    reset,
  } = form;

  const handleModalClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleDelegationRequest = useCallback(
    (formValues: ExpenseDelegationFormValues) => {
      setLoading(true);

      ExpenseService.createDelegationRequest(formValues.users[0].id)
        .then(() => {
          enqueueSnackbar('Delegation request successfully submitted', { variant: 'success' });
        })
        .catch((errorMessage) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
        })
        .finally(() => {
          setLoading(false);
          handleModalClose();
        });
    },
    [enqueueSnackbar, handleModalClose]
  );

  return (
    <FormProvider {...form}>
      <Modal
        open={open}
        onClose={handleModalClose}
        maxWidth={'sm'}
        title={'Request Expense Report Access'}
        loading={loading}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handleModalClose}>
              {'Cancel'}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              type={'submit'}
              disabled={!isValid}
              onClick={handleSubmit(handleDelegationRequest)}
            >
              {'Request'}
            </Button>
          </>
        }
      >
        <Grid {...defaultGridItemProps}>
          {requestDelegateAccessHeader && (
            <div className={styles.contentBlock} dangerouslySetInnerHTML={{ __html: requestDelegateAccessHeader }} />
          )}
        </Grid>
        <Grid {...defaultGridItemProps}>
          <h5 className={commonStyles.subTitle}>{'Enter Email, First or Last Name to Search'}</h5>
          <UserSearchFormItem disabledUserIds={disabledUserIds} />
        </Grid>
      </Modal>
    </FormProvider>
  );
};

export default DelegationRequestModal;
